import { Grid, makeStyles, Theme } from '@material-ui/core';
import { FreeTrialMobileWindow } from '../FreeTrialSide/FreeTrialWindow';
import YouthfullyLogo from '../IntroContainer/YouthfullyLogo';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'none',
        height: `${theme.mobileTopBarHeight}px`,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            paddingTop: '10px'
        }
    },
    endCallButton: {
        height: '28px',
        fontSize: '0.85rem',
        padding: '0 0.6em'
    },
    settingsButton: {
        [theme.breakpoints.down('sm')]: {
            height: '28px',
            minWidth: '28px',
            border: '1px solid rgb(136, 140, 142)',
            padding: 0,
            margin: '0 1em'
        }
    }
}));
export default function MobileTopMenuBar() {
    const classes = useStyles();

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.container}>
            {/* <Typography variant="subtitle1">{room!.name}</Typography>
             */}
            <Grid item xs={6}>
                <YouthfullyLogo style={{ width: '200px' }} />
            </Grid>
            <Grid item xs={6}>
                <FreeTrialMobileWindow />
            </Grid>
        </Grid>
    );
}
