/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Box, IconButton, Link, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Theme, makeStyles } from '@material-ui/core';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import moment from 'moment';
import { Calendar, CalendarSelectEvent } from 'primereact/calendar';
import useBoardActions, { IBoardAction } from '../../../hooks/useAccountBoardAction/useBoardAction';

type Props = {
    actionInfo: IBoardAction;
};
const useStyles = makeStyles((theme: Theme) => ({
    actionContent: {
        resize: 'none',
        backgroundColor: 'transparent',
        width: '260px',
        fontSize: '15px',
        fontFamily: 'Roboto',
        color: '#43464B',
        border: ' solid 0.3px transparent',
        '&:hover': {
            borderColor: '#09C4F7 !important'
        }
    },
    dueDateCalenderInput: {
        width: '60px !important',
        color: '#43464B',
        height: '30px !important',
        fontSize: '15px !important',
        fontFamily: 'Roboto !important',
        fontWeight: 300,
        padding: '5px !important',
        backgroundColor: 'transparent !important',
        borderColor: 'transparent !important',
        '&:hover': {
            borderColor: '#09C4F7 !important'
        }
    }
}));

export const ActionRow = ({ actionInfo }: Props) => {
    const classes = useStyles();
    const { updateAction } = useBoardActions();
    const [actionTitle, setActionTitle] = useState(actionInfo.actionTitle);
    const handleReturnKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            updateAction({ ...actionInfo, actionTitle });
        }
    };

    const handleChangeDueDate = ({ value }: CalendarSelectEvent) => {
        if (Array.isArray(value)) {
            updateAction({ ...actionInfo, dueDate: moment(value[0]).format('YYYY-MM-DD') });
        } else {
            updateAction({ ...actionInfo, dueDate: moment(value).format('YYYY-MM-DD') });
        }
    };

    const handleActionCompleted = (id: string) => {
        if (id) {
            updateAction({ ...actionInfo, actionStatus: 'COMPLETED' });
        }
    };

    useEffect(() => {
        setActionTitle(actionInfo.actionTitle);
    }, [actionInfo]);

    return (
        <TableRow hover role="checkbox" tabIndex={0} sx={{ verticalAlign: 'text-top' }}>
            <TableCell align={'left'} sx={{ padding: '8px 0', borderBottom: 'none' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => handleActionCompleted(actionInfo.id)}>
                        <CheckCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                    <Typography>
                        <TextareaAutosize
                            onChange={e => setActionTitle(e.target.value)}
                            className={classes.actionContent}
                            value={actionTitle}
                            onKeyDown={handleReturnKeyPress}
                            onBlur={() => updateAction({ ...actionInfo, actionTitle })}
                        />
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align={'left'} sx={{ margin: 0, padding: 0, borderBottom: 'none' }}>
                <div className="control has-icons-left">
                    <Tooltip title="Add due date" arrow placement="bottom">
                        <a className="control has-icons-left action-due-date-calender">
                            <Calendar
                                showIcon={false}
                                value={
                                    actionInfo.dueDate ? moment(actionInfo.dueDate).toDate() : null
                                }
                                minDate={moment().toDate()}
                                dateFormat="M dd"
                                icon="pi pi-calendar"
                                inputClassName={classes.dueDateCalenderInput}
                                onSelect={handleChangeDueDate}></Calendar>
                        </a>
                    </Tooltip>
                </div>
            </TableCell>
            <TableCell align={'left'} sx={{ borderBottom: 'none' }}>
                {actionInfo.link && (
                    <Link target={'_blank'} href={actionInfo.link.resourceLink} underline="none">
                        <Box
                            sx={{ transform: 'rotate(45deg)', fontSize: '20px', color: '#A5A5A5' }}>
                            <AttachFileIcon fontSize="inherit" />
                        </Box>
                    </Link>
                )}
            </TableCell>
        </TableRow>
    );
};
