import { useAppState } from '../../state';
import { CallTimer } from '../FreeTrialSide/CallStatus/CallTimer';

export default function CallStatusMain() {
    const { sessionInfo } = useAppState();
    return (
        <CallTimer
            sessionTime={sessionInfo.roomStartTime}
            sessionEndTime={sessionInfo.roomEndTime}
            freeCall={sessionInfo.isFreeTrial}
            localUserName={sessionInfo.userName}
            participantUserName={
                sessionInfo.profile !== 'Coach'
                    ? sessionInfo.sessionCoachName
                    : sessionInfo.sessionClientName
            }></CallTimer>
    );
}
