import React, { useCallback, useEffect } from 'react';
import { Tab, Tabs, styled } from '@mui/material';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';

const FreeTrialTabsHeader = styled(Tabs)(() => ({}));
const FreeTrialSideTab = styled(Tab)(() => ({
    textTransform: 'none',
    width: '50%',
    fontFamily: 'Fira Sans',
    fontSize: '20px',
    color: '#525252 !important',
    fontWeight: '300',
    '&.Mui-selected': {
        fontWeight: 'bold'
    }
}));

export default function ActiveWindowHeader({
    onTabChange,
    withWaiting
}: {
    onTabChange: (value: number) => void;
    withWaiting?: boolean;
}) {
    const [value, setValue] = React.useState<number>();
    const handleChange = useCallback(
        (event: any, newValue: number) => {
            setValue(newValue);
            onTabChange(newValue);
        },
        [onTabChange]
    );
    const screenShareParticipant = useScreenShareParticipant();
    useEffect(() => {
        if (screenShareParticipant) {
            setValue(2);
            onTabChange(2);
        } else {
            if (withWaiting) {
                onTabChange(0);
                setValue(0);
            } else {
                onTabChange(1);
                setValue(1);
            }
        }

        return () => {
            onTabChange(0);
            setValue(0);
        };
    }, [screenShareParticipant, onTabChange, withWaiting]);
    return value !== undefined ? (
        <FreeTrialTabsHeader
            scrollButtons="auto"
            value={value}
            variant="scrollable"
            TabIndicatorProps={{ style: { background: '#525252' } }}
            onChange={handleChange}
            aria-label="basic tabs"
            classes={{
                flexContainer: 'media-side-tabs-container'
            }}>
            {screenShareParticipant && <FreeTrialSideTab label="Participants" value={2} />}
            {withWaiting && <FreeTrialSideTab label="Call Status" value={0} />}

            <FreeTrialSideTab label="Actions" value={1} />
        </FreeTrialTabsHeader>
    ) : (
        <></>
    );
}
