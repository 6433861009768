import { datadogLogs, LogsInitConfiguration, StatusType } from '@datadog/browser-logs';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
const DD_APPLICATION_ID = process.env.REACT_APP_DD_APPLICATION_ID || 'DD_APPLICATION_ID';
const DD_CLIENT = process.env.REACT_APP_DD_CLIENT || 'DD_CLIENT';
const AAMS_ENV = process.env.REACT_APP_AAMS_ENV || 'develop';
const DD_TRACE_DOMAIN = process.env.REACT_APP_DD_TRACE_DOMAIN || 'DD_TRACE_DOMAIN';
const DD_TRACE_DOMAIN_EXT = process.env.REACT_APP_DD_TRACE_DOMAIN_EXT || 'DD_TRACE_DOMAIN_EXT';
const DD_TRACE_LOG_LEVEL = process.env.REACT_APP_DD_TRACE_LOG_LEVEL || 'DD_TRACE_LOG_LEVEL';

export const initDataDog = () => {
    const logsInitObj: LogsInitConfiguration = {
        clientToken: DD_CLIENT,
        site: 'datadoghq.com',
        service: 'app',
        forwardErrorsToLogs: true,
        sampleRate: 100
    };
    const rumInitObj: RumInitConfiguration = {
        applicationId: DD_APPLICATION_ID,
        clientToken: DD_CLIENT,
        site: 'datadoghq.com',
        service: 'app',
        env: AAMS_ENV,
        sampleRate: 100,
        silentMultipleInit: true,
        replaySampleRate: AAMS_ENV === 'production' ? 100 : 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingOrigins: [`/https:\/\/.*\.${DD_TRACE_DOMAIN}\.${DD_TRACE_DOMAIN_EXT}/`]
    };
    const logLevel: StatusType = DD_TRACE_LOG_LEVEL as StatusType;
    datadogLogs.init(logsInitObj);
    datadogLogs.logger.setLevel(logLevel);
    datadogRum.init(rumInitObj);
    datadogRum.startSessionReplayRecording();
};
