import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ChatWindowHeader from './ChatWindowHeader/ChatWindowHeader';
import ChatInput from './ChatInput/ChatInput';
import clsx from 'clsx';
import MessageList from './MessageList/MessageList';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import { Box } from '@mui/material';
import TextMessage from './MessageList/TextMessage/TextMessage';
import theme from '../../theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatWindowContainer: {
            background: '#FFFFFF',
            zIndex: 9,
            display: 'flex',
            flexDirection: 'column',
            borderLeft: '1px solid #E4E7E9',
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 100
            }
        },
        hide: {
            display: 'none'
        }
    })
);

// In this component, we are toggling the visibility of the ChatWindow with CSS instead of
// conditionally rendering the component in the DOM. This is done so that the ChatWindow is
// not unmounted while a file upload is in progress.

export default function ChatWindow() {
    const classes = useStyles();
    const { isChatWindowOpen, messages, conversation } = useChatContext();

    return (
        <Box
            className={clsx(classes.chatWindowContainer, { [classes.hide]: !isChatWindowOpen })}
            sx={{
                position: 'relative',
                width: '100%',
                backgroundColor: '#F8F8F8',
                borderRadius: '10px',
                padding: '8px',
                overflow: 'hidden',
                height: 'calc(100vh - 60px)',
                top: '-20px',
                [theme.breakpoints.down('sm')]: {
                    height: '100%',
                    position: 'fixed',
                    top: 0
                }
            }}>
            <ChatWindowHeader />
            <div style={{ margin: '10px', textAlign: 'center' }}>
                <TextMessage
                    body={
                        'Messages can only be seen by people in the call and are deleted when the call ends'
                    }
                    isLocalParticipant={false}
                />
            </div>
            <MessageList messages={messages} />
            <ChatInput conversation={conversation!} isChatWindowOpen={isChatWindowOpen} />
        </Box>
    );
}
