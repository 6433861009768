import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Box } from '@mui/material';
import CloseIcon from '../../../icons/CloseIcon';
import theme from '../../../theme';
import CallStatusMain from './CallStatusMain';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatWindowContainer: {
            background: '#FFFFFF',
            zIndex: 9,
            display: 'flex',
            flexDirection: 'column',
            borderLeft: '1px solid #E4E7E9',
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: 100
            }
        },
        hide: {
            display: 'none'
        },

        closeBtnContainer: {
            height: '56px',
            background: '#F4F4F6',
            borderBottom: '1px solid #E4E7E9',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 1em'
        },
        closeBtnText: {
            fontSize: '1.25em'
        },
        closeBtnWindow: {
            cursor: 'pointer',
            display: 'flex',
            background: 'transparent',
            border: '0',
            padding: '0.4em'
        }
    })
);

// In this component, we are toggling the visibility of the ChatWindow with CSS instead of
// conditionally rendering the component in the DOM. This is done so that the ChatWindow is
// not unmounted while a file upload is in progress.

export default function CallStatusMobileWindow({
    onClose,
    children,
    title
}: {
    onClose: () => void;
    children: React.ReactNode;
    title: string;
}) {
    const classes = useStyles();

    return (
        <Box
            className={clsx(classes.chatWindowContainer)}
            sx={{
                position: 'relative',
                width: '100%',
                backgroundColor: '#F8F8F8',
                borderRadius: '10px',
                padding: '8px',
                overflow: 'hidden',
                height: 'calc(100vh - 60px)',
                top: '-20px',
                [theme.breakpoints.down('sm')]: {
                    height: '100%',
                    position: 'fixed',
                    top: 0
                }
            }}>
            <div className={classes.closeBtnContainer}>
                <div className={classes.closeBtnText}>{title}</div>
                <button className={classes.closeBtnWindow} onClick={onClose}>
                    <CloseIcon />
                </button>
            </div>
            {children}
        </Box>
    );
}
