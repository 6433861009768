import { API, Amplify } from 'aws-amplify';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import {
    createGoalAction,
    getAccountGoalsBoard,
    updateAccountGoalsBoard,
    updateGoalAction
} from './API';
import _ from 'lodash';
import moment from 'moment';
const awsConnection = {
    aws_project_region: process.env.REACT_APP_AWS_REGION,
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
    aws_appsync_region: process.env.REACT_APP_AWS_REGION,
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY
};
Amplify.configure(awsConnection);

export const loadMyBoard = async (boardId: string) => {
    try {
        const userBoard: any = await API.graphql(
            graphqlOperation(getAccountGoalsBoard, {
                id: _.replace(boardId, 'account', 'board')
            })
        );

        if (userBoard?.data?.getAccountGoalsBoard) {
            const GoalsData = userBoard.data?.getAccountGoalsBoard.goals?.items;
            if (GoalsData?.length) {
                return GoalsData;
            }
        }
    } catch (e) {
        console.error('LOAD USER BOARD ERROR', e);
    }
};

export const createNewAction = async (
    boardId: string,
    goalId: string,
    actionTitle: string,
    profile: string,
    name: string
) => {
    try {
        const responsible = {
            date: moment().toString(),
            name,
            role: profile
        };
        const board = _.replace(boardId, 'account', 'board');
        await API.graphql(
            graphqlOperation(createGoalAction, {
                input: {
                    actionTitle,
                    goalId,
                    actionOrder: 1,
                    actionStatus: 'INCOMPLETE',
                    accountBoardId: board,
                    goalActionGoalId: goalId,
                    responsible: responsible,
                    updatedBy: responsible
                }
            })
        );
    } catch (e) {
        console.error('CREATE NEW ACTION ERROR', e);
    }
};

export const updateActionMutation = async (
    action: any,
    boardId: string,
    name: string,
    profile: string
) => {
    const lastUpdateObj = {
        date: moment().toString(),
        name,
        role: profile
    };
    try {
        await API.graphql(
            graphqlOperation(updateGoalAction, {
                input: {
                    id: action.id,
                    actionTitle: action.actionTitle,
                    actionStatus: action.actionStatus,
                    dueDate: action.dueDate
                }
            })
        );
        const lastUpdate = {
            id: boardId,
            lastUpdateBy: lastUpdateObj
        };
        await API.graphql(
            graphqlOperation(updateAccountGoalsBoard, {
                input: lastUpdate
            })
        );
    } catch (e) {
        console.error('Error in update goal', e);
    }
};
