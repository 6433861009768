import {
    Button,
    Paper,
    Stack,
    TextField,
    styled,
    Typography,
    Box,
    Snackbar,
    Alert
} from '@mui/material';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useAppState } from '../../../state';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
export const CoachCallSurveyOptions = [
    'Complete. Parent & student attended.',
    'Complete. Just parent attended.',
    'Complete. Just student attended.',
    'Incomplete. Client no show.',
    'Incomplete. I forgot.',
    'Incomplete. Technical issue (Enter a Note).'
];
const TOKEN_ENDPOINT = process.env.REACT_APP_TOKEN_ENDPOINT || 'localhost';
export const CoachFreeTrialSurvey = () => {
    const { sessionInfo } = useAppState();
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [techIssue, setTechIssue] = useState<string>('');
    const CallStatusTitle = styled(Typography)({
        fontSize: '22px',
        fontFamily: 'Fira Sans',
        color: '#52D1B7',
        fontWeight: 'bold',
        textAlign: 'center'
    });
    const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
    const handleCoachSurveySubmit = useCallback(async () => {
        let hoursUpdated = 0;
        const optionIndex = _.indexOf(CoachCallSurveyOptions, selectedOption);
        if (optionIndex >= 0 && optionIndex <= 2) {
            hoursUpdated = 0.5;
        }
        persistCoachSessionSurvey(selectedOption, techIssue).then(() => {
            setOpenSuccessSnack(true);
        });

        console.log(`${selectedOption}|=|${techIssue}`, hoursUpdated);
    }, [selectedOption, techIssue]);

    return (
        <Stack
            spacing={2}
            alignItems="center"
            sx={{
                height: '100%',
                paddingTop: 2,
                paddingLeft: 2,
                paddingRight: 2,
                overflow: 'auto'
            }}>
            <CallStatusTitle>
                {_.capitalize(sessionInfo.userName)}, How&apos;d Your Free Trial Call Go With:
            </CallStatusTitle>
            <h3 className="surveySubTitle">{_.capitalize(sessionInfo.sessionClientName)}</h3>
            {sessionInfo.roomStartTime > 0 && (
                <h3 className="surveySubTitle mb-4">
                    {moment.unix(sessionInfo.roomStartTime).format('ddd, MMM DD . hh:mm')} -{' '}
                    {moment.unix(sessionInfo.roomEndTime).format('hh:mm a')}
                </h3>
            )}
            {CoachCallSurveyOptions.map((option, index) => (
                <CoachSurveyOptions
                    className={selectedOption === option ? 'activeOption' : ''}
                    key={index}
                    onClick={() => {
                        if (index !== 5) {
                            setTechIssue('');
                        }
                        setSelectedOption(option);
                    }}>
                    {option}
                </CoachSurveyOptions>
            ))}
            <TechIssueBox>
                {selectedOption === CoachCallSurveyOptions[5] && (
                    <TextField
                        id="outlined-textarea"
                        variant="standard"
                        placeholder="Enter Note"
                        value={techIssue}
                        onChange={e => setTechIssue(e.target.value)}
                        sx={{
                            width: '100%',
                            padding: '10px',
                            maxWidth: '360px'
                        }}
                    />
                )}
            </TechIssueBox>
            <SurveySubmitButton variant="contained" type="button" onClick={handleCoachSurveySubmit}>
                Confirm & Save
            </SurveySubmitButton>
            <CallSmallNote>WE’LL SEND EMAIL FOLLOW UPS IF COMPLETE OR NO SHOW, </CallSmallNote>
            <Snackbar
                open={openSuccessSnack}
                autoHideDuration={6000}
                onClose={() => setOpenSuccessSnack(false)}>
                <Alert
                    onClose={() => setOpenSuccessSnack(false)}
                    severity="success"
                    sx={{ width: '100%' }}>
                    Updated Successfully
                </Alert>
            </Snackbar>
        </Stack>
    );
};

const TechIssueBox = styled(Box)(() => ({
    height: '60px',
    width: '100%'
}));
const SurveySubmitButton = styled(Button)(() => ({
    backgroundColor: '#52D1B7',
    width: '100%',
    padding: '8px',
    maxWidth: '313px',
    fontFamily: 'Fira Sans',
    fontSize: '16px',
    '&:hover': {
        backgroundColor: '#41C6AB'
    }
}));

const CallSmallNote = styled(Typography)(() => ({
    color: '#A5A5A5',
    fontSize: '10px',
    fontFamily: 'Roboto'
}));

const CoachSurveyOptions = styled(Paper)(() => ({
    backgroundColor: '#fff',
    padding: '10px',
    textAlign: 'center',
    color: '#43464B',
    width: '100%',
    maxWidth: '360px',
    cursor: 'pointer',
    boxShadow: 'none',
    fontSize: '15px',
    fontWeight: 'light',
    fontFamily: 'Roboto',
    border: '1px solid #A5A5A5',
    borderRadius: '3px',
    '&.activeOption': {
        border: '1px solid #83FCE3',
        fontSize: '15px',
        fontWeight: 'bold',
        boxShadow: 'rgb(0 0 0 / 33%) 0px 4px 8px'
    },
    '&:hover': {
        border: '1px solid #83FCE3',
        fontSize: '15px',
        fontWeight: 'bold',
        boxShadow: 'rgb(0 0 0 / 33%) 0px 4px 8px'
    }
}));

const persistCoachSessionSurvey = async (responseOption: string, techIssue: string) => {
    const authTokenValue = Cookies.get('authtoken');
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('sessionId');
    if (authTokenValue)
        return fetch(`${TOKEN_ENDPOINT}/api/session/complete`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization: authTokenValue
            },
            body: JSON.stringify({
                sessionId,
                responseOption,
                techIssue
            })
        }).then(res => res.json());

    return false;
};
