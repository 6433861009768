/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    LocalAudioTrack,
    LocalVideoTrack,
    Participant,
    RemoteAudioTrack,
    RemoteVideoTrack
} from 'twilio-video';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Avatar, Typography, styled, useMediaQuery } from '@mui/material';
import _ from 'lodash';
import theme from '../../theme';
import { useClockCounter } from '../../hooks/useTrack/clockCounter';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    infoRowBottom: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0
    },
    identity: {
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '0.1em 0.3em 0.1em 0.3em',
        display: 'inline-flex',
        '& svg': {
            marginLeft: '0.3em'
        },
        margin: '0.5em',
        alignItems: 'center'
    },
    sessionTimer: {
        minWidth: '60px',
        height: '28px',
        display: 'flex',
        marginLeft: '0.5em',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        textAlign: 'center'
    },
    infoContainer: {
        position: 'absolute',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        background: 'transparent',
        top: 0
    },
    reconnectingContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(40, 42, 43, 0.75)',
        zIndex: 1
    },
    fullWidth: {
        gridArea: '1 / 1 / 2 / 3',
        [theme.breakpoints.down('sm')]: {
            gridArea: '1 / 1 / 3 / 3'
        }
    },
    avatarContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#202124',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        '& svg': {
            transform: 'scale(2)'
        }
    },
    recordingIndicator: {
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '0.1em 0.3em 0.1em 0',
        fontSize: '1.2rem',
        height: '28px',
        [theme.breakpoints.down('sm')]: {
            bottom: 'auto',
            right: 0,
            top: 0
        }
    },
    circle: {
        height: '12px',
        width: '12px',
        background: 'red',
        borderRadius: '100%',
        margin: '0 0.6em',
        animation: `1.25s $pulsate ease-out infinite`
    },
    '@keyframes pulsate': {
        '0%': {
            background: `#A90000`
        },
        '50%': {
            background: '#f00'
        },
        '100%': {
            background: '#A90000'
        }
    }
}));

interface MainParticipantInfoProps {
    participant: Participant;
    children: React.ReactNode;
}

export default function MainParticipantInfo({ participant, children }: MainParticipantInfoProps) {
    const classes = useStyles();
    const { room } = useVideoContext();
    const localParticipant = room!.localParticipant;
    const isLocal = localParticipant === participant;

    const publications = usePublications(participant);
    const videoPublication = publications.find(
        p => !p.trackName.includes('screen') && p.kind === 'video'
    );
    const screenSharePublication = publications.find(p => p.trackName.includes('screen'));

    const videoTrack = useTrack(screenSharePublication || videoPublication);
    const isVideoEnabled = Boolean(videoTrack);

    const audioPublication = publications.find(p => p.kind === 'audio');
    const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;

    const isVideoSwitchedOff = useIsTrackSwitchedOff(
        videoTrack as LocalVideoTrack | RemoteVideoTrack
    );
    const isParticipantReconnecting = useParticipantIsReconnecting(participant);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const counterClock = useClockCounter();
    return (
        <div
            data-cy-main-participant
            data-cy-participant={participant.identity}
            className={clsx(classes.container, classes.fullWidth)}>
            <div className={classes.infoContainer}>
                <div className={classes.infoRowBottom}>
                    {isMobile && <div className={classes.sessionTimer}>{counterClock}</div>}
                    {isVideoEnabled && !isVideoSwitchedOff && (
                        <>
                            <div className={classes.identity}>
                                <AudioLevelIndicator audioTrack={audioTrack} />
                                <Typography variant="body1" color="inherit">
                                    {participant.identity ? participant.identity.split('|')[0] : ''}
                                    {!isMobile && isLocal && ' (You)'}
                                    {screenSharePublication && ' - Screen'}
                                </Typography>
                            </div>
                            <NetworkQualityLevel participant={participant} />
                        </>
                    )}
                </div>
            </div>

            {(!isVideoEnabled || isVideoSwitchedOff) && (
                <div className={classes.avatarContainer}>
                    <Avatar
                        sx={{
                            bgcolor: '#BDBDBD',
                            width: '120px',
                            height: '120px',
                            fontSize: '22px'
                        }}>
                        <AvatarParticipantName>
                            {_.upperCase(participant.identity.charAt(0))}
                        </AvatarParticipantName>
                    </Avatar>
                    <AvatarParticipantName>
                        {participant.identity ? participant.identity.split('|')[0] : ''}
                    </AvatarParticipantName>
                </div>
            )}
            {isParticipantReconnecting && (
                <div className={classes.reconnectingContainer}>
                    <Typography variant="body1" style={{ color: 'white' }}>
                        Reconnecting...
                    </Typography>
                </div>
            )}
            {children}
        </div>
    );
}

const AvatarParticipantName = styled(Typography)({
    color: 'white',
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'Fira Sans'
});
