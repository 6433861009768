import { CallTimer } from '../CallStatus/CallTimer';
import { CoachWaitingCheckList } from '../CallStatus/CoachWaitingCheckList';
import { useAppState } from '../../../state';
import { ClientWaitingCheckList } from './ClientWaitingCheckList';
import moment from 'moment-timezone';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import { CoachFreeTrialSurvey } from './CoachFreeTrialSurvey';
import { ClientFreeTrialSurvey } from './ClientFreeTrialSurvey';

export default function CallStatusMain() {
    const participants = useParticipants();
    const { sessionInfo } = useAppState();
    return moment.unix(sessionInfo.roomStartTime).isAfter(moment()) && participants.length < 1 ? (
        <CallTimer
            sessionTime={sessionInfo.roomStartTime}
            sessionEndTime={sessionInfo.roomEndTime}
            freeCall={sessionInfo.isFreeTrial}
            localUserName={sessionInfo.userName}
            participantUserName={
                sessionInfo.profile !== 'Coach'
                    ? sessionInfo.sessionCoachName
                    : sessionInfo.sessionClientName
            }>
            {sessionInfo.profile === 'Coach' ? (
                <CoachWaitingCheckList freeCall={sessionInfo.isFreeTrial} />
            ) : (
                <ClientWaitingCheckList />
            )}
        </CallTimer>
    ) : sessionInfo.profile === 'Coach' ? (
        <CoachFreeTrialSurvey />
    ) : (
        <ClientFreeTrialSurvey />
    );
}
