import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TextField, Typography, styled } from '@mui/material';
import { ActionRow } from './actionComponents/ActionRow';
import useBoardActions, { IBoardAction } from '../../hooks/useAccountBoardAction/useBoardAction';
import { useState } from 'react';
import _ from 'lodash';

interface Column {
    id: 'action' | 'due' | 'link';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'action', label: 'Action', minWidth: 260 },
    { id: 'due', label: 'Due', minWidth: 65 },
    { id: 'link', label: 'Link', minWidth: 8 }
];
export const ActionPortalSide = () => {
    const { actionsByAccount, addNewAction } = useBoardActions();
    const [actionTitle, setActionTitle] = useState('');
    const handleReturnKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addNewAction(actionTitle);
            setActionTitle('');
        }
    };
    return (
        <Paper
            sx={{ width: '100%', overflow: 'hidden', backgroundColor: 'transparent' }}
            elevation={0}>
            <TableContainer
                sx={{
                    maxHeight: '90vh',
                    overflow: 'hidden',
                    maxWidth: '100%',
                    backgroundColor: 'transparent'
                }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    sx={{ backgroundColor: 'transparent' }}
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}>
                                    <ActionTableHeader>{column.label}</ActionTableHeader>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.chain(actionsByAccount)
                            .filter(action => action.actionStatus === 'INCOMPLETE')
                            .orderBy(['dueDate'])
                            .value()
                            .map((action: IBoardAction) => (
                                <ActionRow key={action.id} actionInfo={action} />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <NewAction
                variant="outlined"
                value={actionTitle}
                onKeyPress={handleReturnKeyPress}
                onChange={e => setActionTitle(e.target.value)}
                placeholder="Add an action…"
            />
        </Paper>
    );
};

const ActionTableHeader = styled(Typography)(() => ({
    fontSize: '18px',
    fontFamily: 'Roboto',
    fontWeight: 900,
    color: '#A5A5A5'
}));

const NewAction = styled(TextField)(() => ({
    width: '100%',
    border: 'solid 0.3px transparent',
    '& .MuiInputBase-input': {
        boxSizing: 'border-box',
        '&:hover': {
            borderColor: '#09C4F7 !important'
        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent !important'
    },
    '&:hover': {
        borderColor: '#09C4F7 !important'
    }
}));
