import CallEndIcon from '@material-ui/icons/CallEnd';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { IconButton } from '@mui/material';
import { useAppState } from '../../../state';
import _ from 'lodash';
const APP_ENDPOINT = process.env.REACT_APP_TOKEN_ENDPOINT || 'localhost';

export default function EndCallButton(props: { className?: string }) {
    const { room } = useVideoContext();
    const { sessionInfo } = useAppState();

    const handleCallEndFlow = () => {
        let redirectUrl;
        if (sessionInfo.profile === 'Coach') {
            //redirect to account page
            redirectUrl = `${APP_ENDPOINT}/coach/client/${sessionInfo.accountId}/${_.replace(
                sessionInfo.accountId,
                'account',
                'board'
            )}`;
        } else {
            redirectUrl = `${APP_ENDPOINT}/account/tracker`;
        }
        room!.disconnect();
        window.location.href = redirectUrl;
    };

    return (
        <IconButton
            sx={{
                borderRadius: '25px',
                width: '90px',
                color: '#F35A5A',
                background: '#525252',
                '&:hover': { background: '#525252' }
            }}
            className={props.className}
            onClick={handleCallEndFlow}>
            <CallEndIcon />
        </IconButton>
    );
}
