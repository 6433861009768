import { Paper, styled } from '@mui/material';

export const ClientSurveyOptions = styled(Paper)(() => ({
    backgroundColor: '#fff',
    padding: '10px',
    textAlign: 'center',
    color: '#43464B',
    width: '100%',
    maxWidth: '360px',
    cursor: 'pointer',
    boxShadow: 'none',
    fontSize: '15px',
    fontWeight: 'light',
    fontFamily: 'Roboto',
    border: '1px solid #A5A5A5',
    borderRadius: '3px',
    '&.activeOption': {
        border: '1px solid #83FCE3',
        fontSize: '15px',
        fontWeight: 'bold',
        boxShadow: 'rgb(0 0 0 / 33%) 0px 4px 8px'
    },
    '&:hover': {
        border: '1px solid #83FCE3',
        fontSize: '15px',
        fontWeight: 'bold',
        boxShadow: 'rgb(0 0 0 / 33%) 0px 4px 8px'
    }
}));
