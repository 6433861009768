/* eslint-disable @typescript-eslint/no-shadow */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from './Menu/Menu';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { useMediaQuery } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import theme from '../../theme';
import { Grid, Typography, styled } from '@mui/material';
import { useClockCounter } from '../../hooks/useTrack/clockCounter';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: 'none',
            bottom: 0,
            left: 0,
            right: 0,
            width: 'calc(100% - 450px)',
            height: `${theme.footerHeight}px`,
            position: 'fixed',
            display: 'flex',
            padding: '0 1.43em',
            zIndex: 10,
            [theme.breakpoints.down('sm')]: {
                height: `${theme.mobileFooterHeight}px`,
                width: '100%',
                padding: 0
            }
        },
        screenShareBanner: {
            position: 'fixed',
            zIndex: 8,
            bottom: `${theme.footerHeight}px`,
            left: 0,
            right: 0,
            height: '104px',
            background: 'rgba(0, 0, 0, 0.5)',
            '& h6': {
                color: 'white'
            },
            '& button': {
                background: 'white',
                color: theme.brand,
                border: `2px solid ${theme.brand}`,
                margin: '0 2em',
                '&:hover': {
                    color: '#600101',
                    border: `2px solid #600101`,
                    background: '#FFE9E7'
                }
            }
        },
        hideMobile: {
            display: 'initial',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        }
    })
);

export default function MenuBar() {
    const classes = useStyles();
    const roomState = useRoomState();
    const isReconnecting = roomState === 'reconnecting';
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const counterClock = useClockCounter();
    return (
        <footer className={classes.container}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item sx={{ position: 'relative' }}>
                    <CallTimer>{counterClock}</CallTimer>
                </Grid>

                <Grid item>
                    <Grid container justifyContent="center">
                        <ToggleAudioButton disabled={isReconnecting} />
                        <ToggleVideoButton disabled={isReconnecting} />
                        {!isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
                        <ToggleChatButton />
                        <Menu />
                        <EndCallButton />
                    </Grid>
                </Grid>
            </Grid>
        </footer>
    );
}

const CallTimer = styled(Typography)(() => ({
    color: 'white',
    position: 'absolute',
    zIndex: 1,
    top: '-10px',
    left: '-100px'
}));
