import { Alert, Button, Grid, IconButton, Snackbar, Typography, styled } from '@mui/material';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import Cookies from 'js-cookie';
const TOKEN_ENDPOINT = process.env.REACT_APP_TOKEN_ENDPOINT || 'localhost';
export const RejectRequest = (props: any) => {
    const [reason, setReason] = useState<string | null>(null);
    const [openSuccessSnack, setOpenSuccessSnack] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);

    const handleRejectionReason = useCallback(() => {
        if (_.isString(reason) && reason.length > 2) {
            persistClientSessionSurvey(reason).then(() => {
                setSubmitSuccess(true);
            });
        }
    }, [reason]);
    return submitSuccess ? (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ paddingBottom: 10 }}>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontSize: { xs: '20px', md: '32px' },
                        fontWeight: 'bold',
                        fontFamily: 'Fira Sans',
                        color: '#525252',
                        paddingBottom: '5px',
                        marginBottom: '10px'
                    }}>
                    What’s on Your Mind?
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src="/img/success_icon.svg" srcSet="/img/success_icon.svg" alt="Success" />
            </Grid>
            <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                    sx={{
                        fontSize: { xs: '20px', md: '22px' },
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        color: '#52D1B7',
                        paddingBottom: '5px',
                        marginBottom: '10px'
                    }}>
                    Feedback submitted successfully
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                    sx={{
                        fontSize: '13px',
                        fontWeight: '300',
                        fontFamily: 'Roboto',
                        color: '#000000',
                        paddingBottom: '5px',
                        marginBottom: '10px'
                    }}>
                    Thanks for the feedback. Our Student Success Team will reach out if there’s
                    anything we can do to resolve your questions or concerns. Otherwise you may also
                    email us directly at{' '}
                    <span>
                        <a
                            href="mailto: help@youthfully.ca"
                            style={{
                                fontSize: '13px',
                                color: '#09C4F7',
                                fontWeight: '700',
                                textDecoration: 'underline !important'
                            }}>
                            help@youthfully.ca
                        </a>
                    </span>
                </Typography>
            </Grid>
        </Grid>
    ) : (
        <Grid
            container
            direction="row"
            justifyContent="start"
            alignItems="flex-start"
            spacing={2}
            style={{ paddingBottom: 10 }}>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontSize: { xs: '20px', md: '32px' },
                        fontWeight: 'bold',
                        fontFamily: 'Fira Sans',
                        color: '#525252',
                        paddingBottom: '5px',
                        marginBottom: '10px'
                    }}>
                    <IconButton aria-label="back" onClick={props.goBack}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    What’s on Your Mind?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontSize: '13px',
                        fontWeight: '300',
                        fontFamily: 'Roboto',
                        color: '#000000',
                        paddingLeft: '30px'
                    }}>
                    Please let the Student Success Team know any questions or concerns you have.
                    <span className="has-text-weight-bold">
                        This is <span className="is-underlined">not</span> visible to your coach.
                    </span>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <textarea
                    onChange={e => setReason(e.target.value)}
                    style={{
                        border: '1px solid #E2E2E2',
                        borderRadius: '3px',
                        fontFamily: 'Roboto',
                        width: '100%',
                        height: '190px',
                        resize: 'none',
                        padding: '10px',
                        fontSize: '15px',
                        lineHeight: '1.5'
                    }}
                    className="textarea"
                    placeholder="Example: The coach was good, but I would prefer a coach with more of a tech background because that’s what I want to pursue in my future career."></textarea>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                <GreenButton onClick={handleRejectionReason}>SUBMIT CONFIDENTIALLY</GreenButton>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontSize: '9px',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        color: '#A5A5A5',
                        marginBottom: '10px',
                        textAlign: 'center'
                    }}>
                    THIS FEEDBACK IS NOT VISIBLE TO THE COACH.
                </Typography>
            </Grid>
            <Snackbar
                open={openSuccessSnack}
                autoHideDuration={6000}
                onClose={() => setOpenSuccessSnack(false)}>
                <Alert
                    onClose={() => setOpenSuccessSnack(false)}
                    severity="success"
                    sx={{ width: '100%' }}>
                    Survey Submitted Successfully
                </Alert>
            </Snackbar>
        </Grid>
    );
};

const GreenButton = styled(Button)(() => ({
    backgroundColor: '#52D1B7',
    color: '#FFFFFF',
    padding: '8px 60px',
    borderRadius: '8px',
    '&:hover': {
        backgroundColor: '#41C6AB'
    }
}));

const persistClientSessionSurvey = async (clientNote: string) => {
    const authTokenValue = Cookies.get('authtoken');
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('sessionId');
    if (authTokenValue)
        return fetch(`${TOKEN_ENDPOINT}/api/session/feedback`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization: authTokenValue
            },
            body: JSON.stringify({
                sessionId,
                clientNote
            })
        }).then(res => res.json());

    return false;
};
