import {
    Button,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    Stack,
    Typography,
    styled
} from '@mui/material';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React from 'react';
import _ from 'lodash';
import { GiCheckMark } from 'react-icons/gi';
import { PurchaseNowCard } from '../../SupportPlans/PurchaseNowCard';
import { ClientSurveyOptions } from './FreeTrialSurveyOption';
import { RejectRequest } from './ClientFreeTrialRejectRequest';
import { useAppState } from '../../../state';
const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '';

type Props = {};

export const ClientFreeTrialSurvey = (props: Props) => {
    const { sessionInfo } = useAppState();
    const [clientSelectedOption, setClientSelectedOption] = React.useState<number | null>(null);
    return (
        <Stack
            spacing={2}
            alignItems="center"
            sx={{
                height: '100%',
                paddingTop: 1,
                paddingLeft: 2,
                paddingRight: 2,
                overflow: 'auto',
                pb: 2
            }}>
            {clientSelectedOption === 1 && (
                <>
                    <PurchaseNowCard goBack={() => setClientSelectedOption(null)} />
                </>
            )}
            {clientSelectedOption === 2 && (
                <>
                    <PreBookCardOptionBody goBack={() => setClientSelectedOption(null)} />
                </>
            )}
            {clientSelectedOption === 3 && (
                <>
                    <RejectRequest goBack={() => setClientSelectedOption(null)} {...props} />
                </>
            )}
            {clientSelectedOption === null && (
                <>
                    <Typography
                        sx={{
                            fontSize: '32px',
                            fontFamily: 'Fira Sans',
                            color: '#525252',
                            fontWeight: 'bold',
                            maxWidth: '362px'
                        }}>
                        {_.capitalize(sessionInfo.userName)}, Would you like to proceed with{' '}
                        {_.capitalize(sessionInfo.sessionCoachName)}?
                    </Typography>

                    <ClientSurveyOptions className={''} onClick={() => setClientSelectedOption(1)}>
                        Yes Let`s start ASAP.
                    </ClientSurveyOptions>
                    <ClientSurveyOptions className={''} onClick={() => setClientSelectedOption(2)}>
                        Yes Let`s schedule a later start date.
                    </ClientSurveyOptions>
                    <ClientSurveyOptions className={''} onClick={() => setClientSelectedOption(3)}>
                        No We won`t proceed.
                    </ClientSurveyOptions>
                </>
            )}
        </Stack>
    );
};

const GreenButton = styled(Button)(() => ({
    backgroundColor: '#52D1B7',
    color: '#FFFFFF',
    padding: '8px 60px',
    borderRadius: '8px',
    '&:hover': {
        backgroundColor: '#41C6AB'
    }
}));

const PreBookCardOptionBody = ({ goBack }: { goBack: () => void }) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="start"
            alignItems="flex-start"
            spacing={2}
            style={{ paddingBottom: 10 }}>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontSize: { xs: '20px', md: '32px' },
                        fontWeight: 'bold',
                        fontFamily: 'Fira Sans',
                        color: '#525252',
                        paddingBottom: '5px',
                        marginBottom: '10px'
                    }}>
                    <IconButton aria-label="back" onClick={goBack}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    Pre-Book Your Coach
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        color: '#52D1B7',
                        paddingLeft: '30px'
                    }}>
                    Why pre-book your coach now?
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <List sx={{ width: '100%', paddingLeft: '10px' }}>
                    <ListItem sx={{ pr: 0 }}>
                        <GiCheckMark />
                        <Typography
                            sx={{
                                padding: '0 10px',
                                fontSize: '17px',
                                fontFamily: 'Roboto',
                                color: '#43464B',
                                fontWeight: 'bold'
                            }}>
                            Guarantee a spot (coaches sell out)
                        </Typography>
                    </ListItem>

                    <ListItem sx={{ pr: 0 }}>
                        <GiCheckMark />
                        <Typography
                            sx={{
                                padding: '5px',
                                fontSize: '17px',
                                fontFamily: 'Roboto',
                                color: '#43464B',
                                fontWeight: 'bold'
                            }}>
                            Schedule a future start date
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ pr: 0 }}>
                        <GiCheckMark />
                        <Typography
                            sx={{
                                padding: '5px',
                                fontSize: '17px',
                                fontFamily: 'Roboto',
                                color: '#43464B',
                                fontWeight: 'bold'
                            }}>
                            Change your start date anytime
                        </Typography>
                    </ListItem>
                    <ListItem sx={{ pr: 0 }}>
                        <GiCheckMark />
                        <Typography
                            sx={{
                                padding: '5px',
                                fontSize: '17px',
                                fontFamily: 'Roboto',
                                color: '#43464B',
                                fontWeight: 'bold'
                            }}>
                            Cancel your booking anytime (no charge)
                        </Typography>
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
                <Link
                    href={`${endpoint}/account/purchase_plan`}
                    target={'_blank'}
                    sx={{ textDecoration: 'none' }}>
                    <GreenButton>PRE-BOOK NOW</GreenButton>
                </Link>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontSize: '9px',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        color: '#A5A5A5',
                        marginBottom: '10px',
                        textAlign: 'center'
                    }}>
                    YOU MAY BOOK A FUTURE START DATE UP TO 90 DAYS IN ADVANCE.
                </Typography>
            </Grid>
        </Grid>
    );
};
