import { List, ListItem, Typography } from '@mui/material';
import { GiCheckMark } from 'react-icons/gi';
export const ClientWaitingCheckList = (props: any) => {
    return (
        <List sx={{ width: '100%', maxWidth: 360 }}>
            <ListItem>
                <GiCheckMark />
                <Typography
                    sx={{
                        padding: '5px',
                        fontSize: '17px',
                        fontFamily: 'Roboto',
                        color: '#43464B',
                        fontWeight: 'bold'
                    }}>
                    I’m on my laptop (not phone)
                </Typography>
            </ListItem>
            <ListItem>
                <GiCheckMark />
                <Typography
                    sx={{
                        padding: '5px',
                        fontSize: '17px',
                        fontFamily: 'Roboto',
                        color: '#43464B',
                        fontWeight: 'bold'
                    }}>
                    I have speedy wifi
                </Typography>
            </ListItem>
        </List>
    );
};
