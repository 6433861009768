export const getAccountGoalsBoard = `query GetAccountGoalsBoard($id: ID!) {
    getAccountGoalsBoard(id: $id) {
      id
      goals {
        items {
          id
          goalTitle
          goalTag
          goalOrder
          goalStatus
          goalType
          goalTypeId
          boardShow
          actions(limit: 100) {
            items {
              id
              goalId
              accountBoardId
              actionOrder
              actionStatus
              actionTitle
              dueDate
              commitmentType
              responsible {
                name
                role
                date
              }
              updatedBy {
                role
                date
                name
              }
              createdBy {
                role
                date
                name
              }
              link {
                id
                resourceLink
                resourceName
                createdBy {
                  date
                  name
                  role
                }
              }
            }
          }
        }
        nextToken
      }
      account {
        id
        user {
          id
          firstName
          email
          phone
          timeZone
        }
        coach {
          id
          firstName
          lastName
          email
          capacity
          experience
          avgReviewStars
          phone
          bookingLink
        }
        accountType
        sessionTimeLeftForThisMonth
        sessionsUsedForThisMonth
        myBoard {
          id
        }
        myResources {
          nextToken
        }
        myTrackerOrientation {
          id
          display
          steps
          stepsDone
          dismiss
          createdAt
          updatedAt
        }
      }
      lastUpdateBy {
        date
        name
        role
      }
    }
  }
  `;

export const createGoalAction = /* GraphQL */ `
    mutation CreateGoalAction(
        $input: CreateGoalActionInput!
        $condition: ModelGoalActionConditionInput
    ) {
        createGoalAction(input: $input, condition: $condition) {
            id
            goalId
            accountBoardId
            goal {
                id
                goalTitle
                accountBoardId
                actions {
                    nextToken
                }
                goalBoard {
                    id
                    createdAt
                    updatedAt
                }
                goalOrder
                goalStatus
                goalType
                goalTypeId
                boardShow
                updatedBy {
                    name
                    role
                    date
                }
                createdBy {
                    name
                    role
                    date
                }
                goalTag
                createdAt
                updatedAt
            }
            actionTitle
            actionStatus
            dueDate
            actionOrder
            updatedBy {
                name
                role
                date
            }
            createdBy {
                name
                role
                date
            }
            responsible {
                name
                role
                date
            }
            commitmentType
            completedAt
            priority
            createdAt
            updatedAt
        }
    }
`;

export const onCreateBoardGoalActionByBoardId = /* GraphQL */ `
    subscription OnCreateBoardGoalActionByBoardId($accountBoardId: String!) {
        onCreateBoardGoalActionByBoardId(accountBoardId: $accountBoardId) {
            id
            goalId
            accountBoardId
            goal {
                id
                goalTitle
                accountBoardId
                actions {
                    nextToken
                }
                goalBoard {
                    id
                    createdAt
                    updatedAt
                }
                goalOrder
                goalStatus
                goalType
                goalTypeId
                boardShow
                updatedBy {
                    name
                    role
                    date
                }
                createdBy {
                    name
                    role
                    date
                }
                goalTag
                createdAt
                updatedAt
            }
            actionTitle
            actionStatus
            dueDate
            actionOrder
            updatedBy {
                name
                role
                date
            }
            createdBy {
                name
                role
                date
            }
            responsible {
                name
                role
                date
            }
            commitmentType
            completedAt
            link {
                id
                account {
                    id
                    accountCoachId
                    accountType
                    sessionsUsedForThisMonth
                    sessionTimeLeftForThisMonth
                    sessionExtraHoursForThisMonth
                    paused
                    pausedAt
                    unPausedAt
                    UnpausedTriggerDate
                    UnpausedTriggerPlan
                    canceled
                    cancelReason
                    canceledAt
                    totalAvgPrivateReview
                    lastAvgReview
                    prevLastAvgReview
                    lastPrivateAvgReview
                    prevPrivateLastAvgReview
                    lastPublicAvgReview
                    prevPublicLastAvgReview
                    accountTYPE
                    accountStatus
                    BFeedbackAvailable
                    BLastFeedbackHourThreshold
                    BLastFeedbackCycle
                    PFeedbackAvailable
                    PFeedbackAvailableForParent
                    PLastFeedbackHourThreshold
                    PLastFeedbackCycle
                    PublicFeedbackAvailable
                    PublicFeedbackAvailableForParent
                    PublicLastFeedbackHourThreshold
                    PublicLastFeedbackCycle
                    tagsFoundInBoard
                    lastEmailTriggerForParent
                    lastEmailTriggerForChild
                    subScriptionCustomerId
                    subScriptionSubId
                    subScriptionPlanId
                    subScriptionNextPlanId
                    subScriptionNextPlanStart
                    subScriptionStartDate
                    subScriptionStartDateTimestamp
                    subScriptionStatus
                    subScriptionPeriodStart
                    subScriptionPeriodEnd
                    subscriptionLastInvoice
                    subscriptionPaymentFail
                    accountTimeZone
                    lastUpdated
                    firstName
                    email
                    phone
                    createdAt
                    updatedAt
                }
                actionLinked {
                    nextToken
                }
                resourceName
                resourceLink
                createdBy {
                    name
                    role
                    date
                }
                createdAt
                updatedAt
            }
            priority
            createdAt
            updatedAt
        }
    }
`;

export const onUpdateBoardGoalActionByBoardId = /* GraphQL */ `
    subscription OnUpdateBoardGoalActionByBoardId($accountBoardId: String!) {
        onUpdateBoardGoalActionByBoardId(accountBoardId: $accountBoardId) {
            id
            goalId
            accountBoardId
            goal {
                id
                goalTitle
                accountBoardId
                actions {
                    nextToken
                }
                goalBoard {
                    id
                    createdAt
                    updatedAt
                }
                goalOrder
                goalStatus
                goalType
                goalTypeId
                boardShow
                updatedBy {
                    name
                    role
                    date
                }
                createdBy {
                    name
                    role
                    date
                }
                goalTag
                createdAt
                updatedAt
            }
            actionTitle
            actionStatus
            dueDate
            actionOrder
            updatedBy {
                name
                role
                date
            }
            createdBy {
                name
                role
                date
            }
            responsible {
                name
                role
                date
            }
            commitmentType
            completedAt
            link {
                id
                account {
                    id
                    accountCoachId
                    accountType
                    sessionsUsedForThisMonth
                    sessionTimeLeftForThisMonth
                    sessionExtraHoursForThisMonth
                    paused
                    pausedAt
                    unPausedAt
                    UnpausedTriggerDate
                    UnpausedTriggerPlan
                    canceled
                    cancelReason
                    canceledAt
                    totalAvgPrivateReview
                    lastAvgReview
                    prevLastAvgReview
                    lastPrivateAvgReview
                    prevPrivateLastAvgReview
                    lastPublicAvgReview
                    prevPublicLastAvgReview
                    accountTYPE
                    accountStatus
                    BFeedbackAvailable
                    BLastFeedbackHourThreshold
                    BLastFeedbackCycle
                    PFeedbackAvailable
                    PFeedbackAvailableForParent
                    PLastFeedbackHourThreshold
                    PLastFeedbackCycle
                    PublicFeedbackAvailable
                    PublicFeedbackAvailableForParent
                    PublicLastFeedbackHourThreshold
                    PublicLastFeedbackCycle
                    tagsFoundInBoard
                    lastEmailTriggerForParent
                    lastEmailTriggerForChild
                    subScriptionCustomerId
                    subScriptionSubId
                    subScriptionPlanId
                    subScriptionNextPlanId
                    subScriptionNextPlanStart
                    subScriptionStartDate
                    subScriptionStartDateTimestamp
                    subScriptionStatus
                    subScriptionPeriodStart
                    subScriptionPeriodEnd
                    subscriptionLastInvoice
                    subscriptionPaymentFail
                    accountTimeZone
                    lastUpdated
                    firstName
                    email
                    phone
                    createdAt
                    updatedAt
                }
                actionLinked {
                    nextToken
                }
                resourceName
                resourceLink
                createdBy {
                    name
                    role
                    date
                }
                createdAt
                updatedAt
            }
            priority
            createdAt
            updatedAt
        }
    }
`;

export const updateGoalAction = /* GraphQL */ `
    mutation UpdateGoalAction(
        $input: UpdateGoalActionInput!
        $condition: ModelGoalActionConditionInput
    ) {
        updateGoalAction(input: $input, condition: $condition) {
            id
            goalId
            accountBoardId
            goal {
                id
                goalTitle
                accountBoardId
                actions {
                    nextToken
                }
                goalBoard {
                    id
                    createdAt
                    updatedAt
                }
                goalOrder
                goalStatus
                goalType
                goalTypeId
                boardShow
                updatedBy {
                    name
                    role
                    date
                }
                createdBy {
                    name
                    role
                    date
                }
                goalTag
                createdAt
                updatedAt
            }
            actionTitle
            actionStatus
            dueDate
            actionOrder
            updatedBy {
                name
                role
                date
            }
            createdBy {
                name
                role
                date
            }
            responsible {
                name
                role
                date
            }
            commitmentType
            completedAt
            link {
                id
                account {
                    id
                    accountCoachId
                    accountType
                    sessionsUsedForThisMonth
                    sessionTimeLeftForThisMonth
                    sessionExtraHoursForThisMonth
                    paused
                    pausedAt
                    unPausedAt
                    UnpausedTriggerDate
                    UnpausedTriggerPlan
                    canceled
                    cancelReason
                    canceledAt
                    totalAvgPrivateReview
                    lastAvgReview
                    prevLastAvgReview
                    lastPrivateAvgReview
                    prevPrivateLastAvgReview
                    lastPublicAvgReview
                    prevPublicLastAvgReview
                    accountTYPE
                    accountStatus
                    BFeedbackAvailable
                    BLastFeedbackHourThreshold
                    BLastFeedbackCycle
                    PFeedbackAvailable
                    PFeedbackAvailableForParent
                    PLastFeedbackHourThreshold
                    PLastFeedbackCycle
                    PublicFeedbackAvailable
                    PublicFeedbackAvailableForParent
                    PublicLastFeedbackHourThreshold
                    PublicLastFeedbackCycle
                    tagsFoundInBoard
                    lastEmailTriggerForParent
                    lastEmailTriggerForChild
                    subScriptionCustomerId
                    subScriptionSubId
                    subScriptionPlanId
                    subScriptionNextPlanId
                    subScriptionNextPlanStart
                    subScriptionStartDate
                    subScriptionStartDateTimestamp
                    subScriptionStatus
                    subScriptionPeriodStart
                    subScriptionPeriodEnd
                    subscriptionLastInvoice
                    subscriptionPaymentFail
                    accountTimeZone
                    lastUpdated
                    firstName
                    email
                    phone
                    createdAt
                    updatedAt
                }
                actionLinked {
                    nextToken
                }
                resourceName
                resourceLink
                createdBy {
                    name
                    role
                    date
                }
                createdAt
                updatedAt
            }
            priority
            createdAt
            updatedAt
        }
    }
`;
export const deleteGoalAction = /* GraphQL */ `
    mutation DeleteGoalAction(
        $input: DeleteGoalActionInput!
        $condition: ModelGoalActionConditionInput
    ) {
        deleteGoalAction(input: $input, condition: $condition) {
            id
            goalId
            accountBoardId
            goal {
                id
                goalTitle
                accountBoardId
                actions {
                    nextToken
                }
                goalBoard {
                    id
                    createdAt
                    updatedAt
                }
                goalOrder
                goalStatus
                goalType
                goalTypeId
                boardShow
                updatedBy {
                    name
                    role
                    date
                }
                createdBy {
                    name
                    role
                    date
                }
                goalTag
                createdAt
                updatedAt
            }
            actionTitle
            actionStatus
            dueDate
            actionOrder
            updatedBy {
                name
                role
                date
            }
            createdBy {
                name
                role
                date
            }
            responsible {
                name
                role
                date
            }
            commitmentType
            completedAt
            link {
                id
                account {
                    id
                    accountCoachId
                    accountType
                    sessionsUsedForThisMonth
                    sessionTimeLeftForThisMonth
                    sessionExtraHoursForThisMonth
                    paused
                    pausedAt
                    unPausedAt
                    UnpausedTriggerDate
                    UnpausedTriggerPlan
                    canceled
                    cancelReason
                    canceledAt
                    totalAvgPrivateReview
                    lastAvgReview
                    prevLastAvgReview
                    lastPrivateAvgReview
                    prevPrivateLastAvgReview
                    lastPublicAvgReview
                    prevPublicLastAvgReview
                    accountTYPE
                    accountStatus
                    BFeedbackAvailable
                    BLastFeedbackHourThreshold
                    BLastFeedbackCycle
                    PFeedbackAvailable
                    PFeedbackAvailableForParent
                    PLastFeedbackHourThreshold
                    PLastFeedbackCycle
                    PublicFeedbackAvailable
                    PublicFeedbackAvailableForParent
                    PublicLastFeedbackHourThreshold
                    PublicLastFeedbackCycle
                    tagsFoundInBoard
                    lastEmailTriggerForParent
                    lastEmailTriggerForChild
                    subScriptionCustomerId
                    subScriptionSubId
                    subScriptionPlanId
                    subScriptionNextPlanId
                    subScriptionNextPlanStart
                    subScriptionStartDate
                    subScriptionStartDateTimestamp
                    subScriptionStatus
                    subScriptionPeriodStart
                    subScriptionPeriodEnd
                    subscriptionLastInvoice
                    subscriptionPaymentFail
                    accountTimeZone
                    lastUpdated
                    firstName
                    email
                    phone
                    createdAt
                    updatedAt
                }
                actionLinked {
                    nextToken
                }
                resourceName
                resourceLink
                createdBy {
                    name
                    role
                    date
                }
                createdAt
                updatedAt
            }
            priority
            createdAt
            updatedAt
        }
    }
`;

export const updateAccountGoalsBoard = /* GraphQL */ `
    mutation UpdateAccountGoalsBoard(
        $input: UpdateAccountGoalsBoardInput!
        $condition: ModelAccountGoalsBoardConditionInput
    ) {
        updateAccountGoalsBoard(input: $input, condition: $condition) {
            id
            goals {
                items {
                    id
                    goalTitle
                    accountBoardId
                    goalOrder
                    goalStatus
                    goalType
                    goalTypeId
                    boardShow
                    goalTag
                    createdAt
                    updatedAt
                }
                nextToken
            }
            account {
                id
                accountCoachId
                user {
                    id
                    firstName
                    email
                    phone
                    userOnBoardingResponsesId
                    subScriptionCustomerId
                    subScriptionSubId
                    subScriptionPlanId
                    subScriptionNextPlanId
                    subScriptionNextPlanStart
                    subScriptionStartDate
                    subScriptionStartDateTimestamp
                    subScriptionStatus
                    subScriptionPeriodStart
                    subScriptionPeriodEnd
                    subscriptionLastInvoice
                    subscriptionPaymentFail
                    timeZone
                    profilePic
                    profileType
                    createdAt
                    updatedAt
                }
                coach {
                    id
                    connectId
                    connectStatus
                    firstName
                    lastName
                    email
                    capacity
                    experience
                    avgReviewStars
                    phone
                    bookingLink
                    currentRate
                    coachStatus
                    coachHighlights
                    personalEmail
                    location
                    nextAvailabilitySync
                    lmsId
                    createdAt
                    updatedAt
                }
                accountType
                sessionsUsedForThisMonth
                sessionTimeLeftForThisMonth
                sessionExtraHoursForThisMonth
                myBoard {
                    id
                    createdAt
                    updatedAt
                }
                myResources {
                    nextToken
                }
                myTrackerOrientation {
                    id
                    display
                    steps
                    stepsDone
                    dismiss
                    createdAt
                    updatedAt
                }
                accountLinks {
                    nextToken
                }
                paused
                pausedAt
                unPausedAt
                UnpausedTriggerDate
                UnpausedTriggerPlan
                canceled
                cancelReason
                canceledAt
                privateReviews {
                    nextToken
                }
                totalAvgPrivateReview
                lastAvgReview
                prevLastAvgReview
                lastPrivateAvgReview
                prevPrivateLastAvgReview
                lastPublicAvgReview
                prevPublicLastAvgReview
                accountTYPE
                accountStatus
                BFeedbackAvailable
                BLastFeedbackHourThreshold
                BLastFeedbackCycle
                PFeedbackAvailable
                PFeedbackAvailableForParent
                PLastFeedbackHourThreshold
                PLastFeedbackCycle
                PublicFeedbackAvailable
                PublicFeedbackAvailableForParent
                PublicLastFeedbackHourThreshold
                PublicLastFeedbackCycle
                tagsFoundInBoard
                lastEmailTriggerForParent
                lastEmailTriggerForChild
                subScriptionCustomerId
                subScriptionSubId
                subScriptionPlanId
                subScriptionNextPlanId
                subScriptionNextPlanStart
                subScriptionStartDate
                subScriptionStartDateTimestamp
                subScriptionStatus
                subScriptionPeriodStart
                subScriptionPeriodEnd
                subscriptionLastInvoice
                subscriptionPaymentFail
                accountTimeZone
                lastUpdated
                firstName
                email
                phone
                subscriptionHistory {
                    nextToken
                }
                sessionsBooked {
                    nextToken
                }
                createdAt
                updatedAt
            }
            lastUpdateBy {
                name
                role
                date
            }
            createdAt
            updatedAt
        }
    }
`;
