import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { Theme, useMediaQuery } from '@material-ui/core';
import DeviceSelectionDialog from '../../../DeviceSelectionDialog/DeviceSelectionDialog';
import SettingsIcon from '../../../../icons/SettingsIcon';

export default function SettingsMenu({
    mobileButtonClass,
    className
}: {
    mobileButtonClass?: string;
    className?: string;
}) {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);

    const anchorRef = useRef<HTMLButtonElement>(null);

    return (
        <>
            {isMobile ? (
                <Button
                    ref={anchorRef}
                    onClick={() => setDeviceSettingsOpen(true)}
                    startIcon={<SettingsIcon />}
                    className={mobileButtonClass}>
                    Devices
                </Button>
            ) : (
                <Button
                    className={className}
                    ref={anchorRef}
                    onClick={() => setDeviceSettingsOpen(true)}
                    startIcon={<SettingsIcon />}>
                    Devices
                </Button>
            )}
            <DeviceSelectionDialog
                open={deviceSettingsOpen}
                onClose={() => {
                    setDeviceSettingsOpen(false);
                }}
            />
        </>
    );
}
