import ReactDOM from 'react-dom';

import { CircularProgress, CssBaseline, Grid, Typography } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { ParticipantProvider } from './components/ParticipantProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

const VideoApp = () => {
    const { error, setError, isTokenValidInprogress } = useAppState();
    const connectionOptions = useConnectionOptions();

    return isTokenValidInprogress ? (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{ height: '100%' }}>
            <div>
                <CircularProgress variant="indeterminate" />
            </div>
            <div>
                <Typography
                    variant="body2"
                    style={{ fontWeight: 'bold', fontSize: '16px', color: '#fff' }}>
                    Joining Meeting
                </Typography>
            </div>
        </Grid>
    ) : (
        <VideoProvider options={connectionOptions} onError={setError}>
            <ErrorDialog dismissError={() => setError(null)} error={error} />
            <ParticipantProvider>
                <ChatProvider>
                    <App />
                </ChatProvider>
            </ParticipantProvider>
        </VideoProvider>
    );
};

export const ReactApp = () => {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <UnsupportedBrowserWarning>
                <Router>
                    <AppStateProvider>
                        <PrivateRoute exact path="/">
                            <VideoApp />
                        </PrivateRoute>
                    </AppStateProvider>
                </Router>
            </UnsupportedBrowserWarning>
        </MuiThemeProvider>
    );
};

ReactDOM.render(<ReactApp />, document.getElementById('root'));
