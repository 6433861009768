import Cookies from 'js-cookie';

const TOKEN_ENDPOINT = process.env.REACT_APP_TOKEN_ENDPOINT || 'localhost';
export const requestUserFeedback = async (roomId: string) => {
    try {
        const authTokenValue = Cookies.get('authtoken');
        if (authTokenValue) {
            await fetch(`${TOKEN_ENDPOINT}/api/session/feedback_request`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    Authorization: authTokenValue
                },
                body: JSON.stringify({
                    roomId
                })
            }).then(res => res.json());
        }
    } catch (e) {
        console.error('Send feedback request failed', e);
    }
};
