import moment from 'moment';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import useVideoContext from '../useVideoContext/useVideoContext';
import { requestUserFeedback } from '../useSessionFeedback/sessionFeedbackAPI';

const REQUEST_FEEDBACK_THRESHOLD = parseInt(
    process.env.REACT_APP_REQUEST_FEEDBACK_THRESHOLD || '1'
);
export const useClockCounter = () => {
    const { room } = useVideoContext();
    const [counterClock, setCounterClock] = useState<string>();
    const [counterInMin, setCounterInMin] = useState<number>();
    const [requestFeedbackRequested, setRequestFeedbackRequested] = useState(false);
    let counter = 0;
    useEffect(() => {
        const timerInterval = setInterval(() => {
            setCounterInMin(_.round(counter / 60, 2));
            setCounterClock(
                moment()
                    .hour(0)
                    .minute(0)
                    .second(counter++)
                    .format(counter > 3600 ? 'HH : mm : ss' : 'mm : ss')
            );
        }, 1000);

        return () => {
            clearInterval(timerInterval);
            setCounterClock(undefined);
        };
    }, [counter]);

    useEffect(() => {
        const participants = room?.participants.size;
        if (
            counterInMin &&
            participants &&
            participants > 0 &&
            counterInMin > REQUEST_FEEDBACK_THRESHOLD &&
            !requestFeedbackRequested &&
            room?.sid
        ) {
            requestUserFeedback(room?.sid);
            setRequestFeedbackRequested(true);
        }
    }, [counterInMin, requestFeedbackRequested, room?.participants.size, room?.sid]);

    return counterClock;
};
