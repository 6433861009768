import { Grid, Typography } from '@mui/material';
import { PLAN_SUPPORT_TYPE, SupportPackage } from './PackagesUtils';

export const PlanSideRow = ({ planData }: { planData: SupportPackage }) => {
    return (
        <Grid item xs={12} sx={{ borderBottom: '1px solid #E4E4E4' }}>
            <Grid container sx={{ padding: '15px 0px' }}>
                <Grid item xs={8}>
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            fontFamily: 'Fira Sans',
                            color: planData.themeColor
                        }}>
                        {planData.displayName}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: { xs: '12px', lg: '12px' },
                            fontWeight: '300',
                            fontFamily: 'Roboto',
                            color: '#000000'
                        }}>
                        {planData.purchaseDesc}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            fontFamily: 'Fira Sans',
                            color: planData.themeColor,
                            textAlign: 'left'
                        }}>
                        {planData.priceString}
                        <span
                            style={{
                                fontSize: '12px',
                                fontWeight: '400',
                                fontFamily: 'Fira Sans',
                                position: 'relative',
                                color: planData.themeColor,
                                top: '-8px'
                            }}>
                            {' '}
                            / MONTH
                        </span>
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: { xs: '11px', lg: '12px' },
                            fontWeight: '300',
                            fontFamily: 'Roboto',
                            color: '#000000',
                            textAlign: 'left'
                        }}>
                        $CAD,{' '}
                        {planData.type === PLAN_SUPPORT_TYPE.MONTHLY
                            ? 'paid monthly'
                            : 'paid yearly'}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
