import { useAppState } from '../../state';
import AccountActionsWindow from '../ActiveAccountActions/AccountActionsWindow';
import FreeTrialWindow from '../FreeTrialSide/FreeTrialWindow';

export default function MainDrawer() {
    const { sessionInfo } = useAppState();

    // Joined early ?
    // if Coach Show 2 tabs
    // if Student Show 1 tab

    return sessionInfo?.isFreeTrial ? <FreeTrialWindow /> : <AccountActionsWindow />;
}
