export enum PLAN_SUPPORT_TYPE {
    MONTHLY = 'monthly',
    ANNUAL = 'annual'
}
export interface SupportPackage {
    name: string;
    price: number;
    currency: string;
    css_planId: string;
    capacity: number;
    themeColor?: string;
    displayName?: string;
    summary?: string;
    priceString?: string;
    shortCut?: string;
    type?: PLAN_SUPPORT_TYPE;
    tag?: string;
    description?: string;
    purchaseDesc?: string;
}
export const getAnnualPackages = () => [
    {
        name: 'Basic Support',
        price: 443,
        currency: 'CAD',
        css_planId: 'basic_support_plan',
        capacity: 4,
        themeColor: '#525252',
        displayName: 'BASIC SUPPORT',
        summary: '4 coaching hours / year',
        description: 'For students wanting to try this out & get on the right path.',
        priceString: '$37',
        shortCut: 'Basic',
        type: PLAN_SUPPORT_TYPE.ANNUAL,
        purchaseDesc: '4 coaching hours / year'
    },
    {
        name: 'Essential Support',
        price: 879,
        currency: 'CAD',
        css_planId: 'essential_support_plan',
        capacity: 8,
        description: 'For students looking to accomplish more and get to the next level.',
        themeColor: '#01B3E4',
        displayName: 'ESSENTIAL SUPPORT',
        summary: '8 coaching hours / year',
        priceString: '$73',
        shortCut: 'Essential',
        type: PLAN_SUPPORT_TYPE.ANNUAL,
        tag: 'Most Popular',
        purchaseDesc: '8 coaching hours / year'
    },
    {
        name: 'Standard Support',
        price: 1525,
        currency: 'CAD',
        css_planId: 'standard_support_plan',
        capacity: 14,
        themeColor: '#FFC93D',
        description: 'For go-getters pursuing a big goal or just seeking regular coaching.',
        displayName: 'STANDARD SUPPORT',
        summary: '14 coaching hours / year',
        priceString: '$127',
        shortCut: 'Standard',
        type: PLAN_SUPPORT_TYPE.ANNUAL,
        purchaseDesc: '14 coaching hours / year'
    },
    {
        name: 'Premium Support',
        price: 2385,
        currency: 'CAD',
        css_planId: 'premium_support_plan',
        capacity: 22,
        themeColor: '#52D1B7',
        description: 'For ambitious students eager to grow & crush multiple goals.',
        displayName: 'PREMIUM SUPPORT',
        summary: '22 coaching hours / year',
        priceString: '$199',
        shortCut: 'Premium',
        type: PLAN_SUPPORT_TYPE.ANNUAL,
        tag: 'BEST VALUE',
        purchaseDesc: '22 coaching hours / year'
    },
    {
        name: '1-Hour Top-Up',
        price: 139,
        currency: 'CAD',
        css_planId: 'hour_top_up',
        capacity: 1,
        themeColor: '#F35A5A',
        description: 'For aspiring achievers looking to make a big push towards a goal.',
        shortCut: 'hourTopUp',
        displayName: '1-HOUR TOP-UP',
        summary:
            'This is a non-recurring  (one-time) top-up, also expiring at the end of the current cycle. Multiple top-ups are permitted.',
        priceString: '$139'
    }
];

export const getAllPackages = () => [
    {
        name: 'Basic Support',
        price: 139,
        currency: 'CAD',
        css_planId: 'basic_support_plan',
        capacity: 1,
        themeColor: '#525252',
        displayName: 'BASIC SUPPORT',
        summary: '1 coaching hour / month',
        priceString: '$139',
        description: 'For students wanting monthly sessions for continued progress.',
        shortCut: 'Basic',
        type: PLAN_SUPPORT_TYPE.MONTHLY,
        purchaseDesc: '1 hour / month • Available After Month 1'
    },
    {
        name: 'Essential Support',
        tag: 'Most Popular',
        price: 395,
        currency: 'CAD',
        css_planId: 'essential_support_plan',
        capacity: 3,
        themeColor: '#01B3E4',
        displayName: 'ESSENTIAL SUPPORT',
        summary: '3 coaching hours / month',
        priceString: '$395',
        shortCut: 'Essential',
        description: 'For students eager to clarify & kick-start their goals.',
        type: PLAN_SUPPORT_TYPE.MONTHLY,
        purchaseDesc: '3 hours / month • 5% Savings'
    },
    {
        name: 'Standard Support',
        price: 735,
        currency: 'CAD',
        css_planId: 'standard_support_plan',
        capacity: 6,
        themeColor: '#FFC93D',
        displayName: 'STANDARD SUPPORT',
        summary: '6 coaching hours / month',
        priceString: '$735',
        shortCut: 'Standard',
        description: 'For aspiring achievers looking to make a big push towards a goal.',
        type: PLAN_SUPPORT_TYPE.MONTHLY,
        purchaseDesc: '6 hours / month • 12% Savings'
    },
    {
        name: 'Premium Support',
        price: 999,
        currency: 'CAD',
        css_planId: 'premium_support_plan',
        capacity: 9,
        themeColor: '#52D1B7',
        displayName: 'PREMIUM SUPPORT',
        summary: '9 coaching hours / month',
        priceString: '$999',
        shortCut: 'Premium',
        tag: 'BEST VALUE',
        description: 'For go-getters seeking to conquer a high-priority goal.',
        type: PLAN_SUPPORT_TYPE.MONTHLY,
        purchaseDesc: '9 hours / month • 20% Savings'
    },
    {
        name: '1-Hour Top-Up',
        price: 139,
        currency: 'CAD',
        css_planId: 'hour_top_up',
        capacity: 1,
        themeColor: '#F35A5A',
        shortCut: 'hourTopUp',
        displayName: '1-HOUR TOP-UP',
        description: 'For aspiring achievers looking to make a big push towards a goal.',
        summary:
            'This is a non-recurring  (one-time) top-up, also expiring at the end of the current cycle. Multiple top-ups are permitted.',
        priceString: '$139'
    }
];
