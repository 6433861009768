import { List, ListItem, Typography } from '@mui/material';
import { GiCheckMark } from 'react-icons/gi';
export const CoachWaitingCheckList = (props: any) => {
    return (
        <List sx={{ width: '100%', maxWidth: 360 }}>
            {props.freeCall && (
                <ListItem>
                    <GiCheckMark />
                    <Typography
                        sx={{
                            padding: '0 10px',
                            fontSize: '17px',
                            fontFamily: 'Roboto',
                            color: '#43464B',
                            fontWeight: 'bold'
                        }}>
                        I’ve reviewed the Intro Session Guide
                        <br />
                        <span style={{ color: '#52D1B7' }}>(click the “Guide” tab at the top)</span>
                    </Typography>
                </ListItem>
            )}
            {props.freeCall && (
                <>
                    <ListItem>
                        <GiCheckMark />
                        <Typography
                            sx={{
                                padding: '5px',
                                fontSize: '17px',
                                fontFamily: 'Roboto',
                                color: '#43464B',
                                fontWeight: 'bold'
                            }}>
                            I’m present and engaged
                        </Typography>
                    </ListItem>

                    <ListItem>
                        <GiCheckMark />
                        <Typography
                            sx={{
                                padding: '5px',
                                fontSize: '17px',
                                fontFamily: 'Roboto',
                                color: '#43464B',
                                fontWeight: 'bold'
                            }}>
                            I’m in a quiet space
                        </Typography>
                    </ListItem>
                </>
            )}
            <ListItem>
                <GiCheckMark />
                <Typography
                    sx={{
                        padding: '5px',
                        fontSize: '17px',
                        fontFamily: 'Roboto',
                        color: '#43464B',
                        fontWeight: 'bold'
                    }}>
                    I have speedy wifi
                </Typography>
            </ListItem>
        </List>
    );
};
