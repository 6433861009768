import { useState } from 'react';
import FreeTrialWindowHeader from './FreeTrialWindowHeader';
import { Box, Chip, styled } from '@mui/material';
import CallStatusMain from './CallStatus/CallStatusMain';
import { useAppState } from '../../state';
import { CoachQuickIntroGuide } from './CoachGuide/coachGuideClues';
import ParticipantList from '../ParticipantList/ParticipantList';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import FreeTrialWindowHeadWithParticipant from './FreeTrialWindowHeadWithParticipant';
import theme from '../../theme';
import CallStatusMobileWindow from './CallStatus/CallStatusWindow';
import { ActionPortalSide } from '../ActiveAccountActions/ActionPortalSide';
import moment from 'moment';
import useParticipants from '../../hooks/useParticipants/useParticipants';

enum Tabs {
    CallStatus,
    CoachGuide,
    Participants
}
export default function FreeTrialWindow() {
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const screenShareParticipant = useScreenShareParticipant();
    const { sessionInfo } = useAppState();
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                backgroundColor: '#F8F8F8',
                borderRadius: '10px',
                padding: '8px',
                overflow: 'hidden',
                height: 'calc(100vh - 60px)',
                top: '-20px'
            }}>
            {sessionInfo?.profile === 'Coach' && (
                <FreeTrialWindowHeader onTabChange={setSelectedTab} />
            )}
            {sessionInfo?.profile !== 'Coach' && screenShareParticipant && (
                <FreeTrialWindowHeadWithParticipant onTabChange={setSelectedTab} />
            )}

            {selectedTab === Tabs.CallStatus && <CallStatusMain />}
            {selectedTab === Tabs.CoachGuide && <CoachQuickIntroGuide />}
            {selectedTab === Tabs.Participants && <ParticipantList />}
        </Box>
    );
}

export const FreeTrialMobileWindow = () => {
    const [tabSelected, setTabSelected] = useState(-1);
    const participants = useParticipants();
    const { sessionInfo } = useAppState();
    const withWaiting =
        moment.unix(sessionInfo.roomStartTime).isAfter(moment()) && participants.length < 1;
    return sessionInfo?.isFreeTrial ? (
        <Box
            sx={{
                paddingLeft: '1em',
                display: 'none',
                height: `${theme.mobileTopBarHeight}px`,
                [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    top: 0,
                    zIndex: 100
                }
            }}>
            <MobileTab label="Call Status" onClick={() => setTabSelected(Tabs.CallStatus)} />
            {sessionInfo?.profile === 'Coach' && (
                <MobileTab label="Coach Guide" onClick={() => setTabSelected(Tabs.CoachGuide)} />
            )}
            {tabSelected === Tabs.CallStatus && (
                <CallStatusMobileWindow onClose={() => setTabSelected(-1)} title={'Call status'}>
                    <CallStatusMain />
                </CallStatusMobileWindow>
            )}

            {tabSelected === Tabs.CoachGuide && (
                <CallStatusMobileWindow onClose={() => setTabSelected(-1)} title={'Coach Guide'}>
                    <CoachQuickIntroGuide />
                </CallStatusMobileWindow>
            )}
        </Box>
    ) : (
        <Box
            sx={{
                paddingLeft: '1em',
                display: 'none',
                height: `${theme.mobileTopBarHeight}px`,
                [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    top: 0,
                    zIndex: 100
                }
            }}>
            {withWaiting && (
                <MobileTab label="Call Status" onClick={() => setTabSelected(Tabs.CallStatus)} />
            )}
            <MobileTab label="Actions" onClick={() => setTabSelected(Tabs.CoachGuide)} />
            {tabSelected === Tabs.CallStatus && (
                <CallStatusMobileWindow onClose={() => setTabSelected(-1)} title={'Call status'}>
                    <CallStatusMain />
                </CallStatusMobileWindow>
            )}

            {tabSelected === Tabs.CoachGuide && (
                <CallStatusMobileWindow onClose={() => setTabSelected(-1)} title={'Actions'}>
                    <ActionPortalSide />
                </CallStatusMobileWindow>
            )}
        </Box>
    );
};

const MobileTab = styled(Chip)(() => ({
    backgroundColor: 'white',
    marginRight: '1em',
    '&:hover': {
        backgroundColor: 'white'
    },
    '&:active': {
        backgroundColor: 'white'
    }
}));
