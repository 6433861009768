import { Grid, Paper, Stack, Typography, styled } from '@mui/material';
import moment from 'moment-timezone';
import React from 'react';
import _ from 'lodash';

const TimerPaper = styled(Paper)(() => ({
    background: '#52D1B7',
    width: '78px',
    height: '78px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '55px',
    fontfamily: 'Fira Sans',
    fontWeight: '600',
    textAlign: 'center',
    color: '#fff'
}));

export const CallTimer = (props: any) => {
    const myTimeZone = moment.tz.guess();
    const [hoursLeft, setHoursLeft] = React.useState(0);
    const [minLeft, setMinLeft] = React.useState(0);
    const [secLeft, setSecLeft] = React.useState(0);
    React.useEffect(() => {
        const timerInterval = setInterval(() => {
            const duration = moment.duration(
                moment
                    .unix(props.sessionTime)
                    .tz(moment.tz.guess())
                    .diff(moment())
            );

            setHoursLeft(duration.hours() + duration.days() * 24);
            setMinLeft(duration.minutes());
            setSecLeft(duration.seconds());
        }, 1000);
        return () => {
            clearInterval(timerInterval);
            setHoursLeft(0);
            setMinLeft(0);
            setSecLeft(0);
        };
    }, [props.sessionTime]);

    const TimerHeader = styled(Typography)(() => ({
        color: '#52D1B7',
        fontSize: '28px',
        fontWeight: 'bold',
        fontFamily: 'Fira Sans',
        textAlign: 'center'
    }));

    const TimerSubHeader = styled(Typography)(() => ({
        color: '#43464B',
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        textAlign: 'center'
    }));

    const TimerProps = styled(Typography)(() => ({
        color: '#A5A5A5',
        fontSize: '10px',
        fontFamily: 'Roboto',
        textAlign: 'center',
        paddingTop: '10px'
    }));

    return (
        <Stack
            spacing={2}
            alignItems="center"
            sx={{
                height: '100%',
                paddingTop: 5,
                paddingLeft: 2,
                paddingRight: 2,
                overflow: 'auto'
            }}>
            <TimerHeader>
                {_.capitalize(props.localUserName)}, Your Call will Begin Shortly With:
            </TimerHeader>
            <TimerSubHeader className="surveySubTitle">{props.participantUserName}</TimerSubHeader>
            <TimerSubHeader>
                {moment
                    .unix(props.sessionTime)
                    .tz(myTimeZone)
                    .format('ddd, MMM DD . hh:mm')}{' '}
                -{' '}
                {moment
                    .unix(props.sessionEndTime)
                    .tz(myTimeZone)
                    .format('hh:mm a')}
            </TimerSubHeader>
            <Grid
                container
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ maxWidth: '400px' }}>
                <Grid
                    item
                    xs={4}
                    className="is-flex is-justify-content-center is-flex-direction-column is-align-items-center">
                    <TimerPaper elevation={0}>{Math.max(hoursLeft, 0)}</TimerPaper>
                    <TimerProps>HOURS</TimerProps>
                </Grid>
                <Grid
                    item
                    xs={4}
                    className="is-flex is-justify-content-center is-flex-direction-column is-align-items-center">
                    <TimerPaper elevation={0}>{Math.max(minLeft, 0)}</TimerPaper>
                    <TimerProps>MINUTES</TimerProps>
                </Grid>
                <Grid
                    item
                    xs={4}
                    className="is-flex is-justify-content-center is-flex-direction-column is-align-items-center">
                    <TimerPaper elevation={0}>{Math.max(secLeft, 0)}</TimerPaper>
                    <TimerProps>SECONDS</TimerProps>
                </Grid>
            </Grid>
            {props.children}
        </Stack>
    );
};
