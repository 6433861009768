import { useState } from 'react';
import { Box, Chip, styled } from '@mui/material';
import { useAppState } from '../../state';
import ParticipantList from '../ParticipantList/ParticipantList';
import theme from '../../theme';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import { CoachQuickIntroGuide } from '../FreeTrialSide/CoachGuide/coachGuideClues';
import CallStatusMobileWindow from '../FreeTrialSide/CallStatus/CallStatusWindow';
import CallStatusMain from './CallStatusMain';
import moment from 'moment';
import ActiveWindowHeader from './ActiveWindowHeader';
import { ActionPortalSide } from './ActionPortalSide';

enum Tabs {
    CallStatus,
    Actions,
    Participants
}
export default function AccountActionsWindow() {
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const participants = useParticipants();
    const { sessionInfo } = useAppState();
    const withWaiting =
        moment.unix(sessionInfo.roomStartTime).isAfter(moment()) && participants.length < 1;
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                backgroundColor: '#F8F8F8',
                borderRadius: '10px',
                padding: '8px',
                overflow: 'hidden',
                height: 'calc(100vh - 60px)',
                top: '-20px'
            }}>
            <ActiveWindowHeader onTabChange={setSelectedTab} withWaiting={withWaiting} />

            {selectedTab === Tabs.CallStatus && <CallStatusMain />}
            {selectedTab === Tabs.Actions && <ActionPortalSide />}
            {selectedTab === Tabs.Participants && <ParticipantList />}
        </Box>
    );
}

export const FreeTrialMobileWindow = () => {
    const [tabSelected, setTabSelected] = useState(-1);
    const { sessionInfo } = useAppState();
    return (
        <Box
            sx={{
                paddingLeft: '1em',
                display: 'none',
                height: `${theme.mobileTopBarHeight}px`,
                [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    top: 0,
                    zIndex: 100
                }
            }}>
            <MobileTab label="Call Status" onClick={() => setTabSelected(Tabs.CallStatus)} />
            {sessionInfo?.profile === 'Coach' && (
                <MobileTab label="Coach Guide" onClick={() => setTabSelected(Tabs.Actions)} />
            )}
            {tabSelected === Tabs.CallStatus && (
                <CallStatusMobileWindow onClose={() => setTabSelected(-1)} title={'Call status'}>
                    <CallStatusMain />
                </CallStatusMobileWindow>
            )}

            {tabSelected === Tabs.Actions && (
                <CallStatusMobileWindow onClose={() => setTabSelected(-1)} title={'Coach Guide'}>
                    <CoachQuickIntroGuide />
                </CallStatusMobileWindow>
            )}
        </Box>
    );
};

const MobileTab = styled(Chip)(() => ({
    backgroundColor: 'white',
    marginRight: '1em',
    '&:hover': {
        backgroundColor: 'white'
    },
    '&:active': {
        backgroundColor: 'white'
    }
}));
