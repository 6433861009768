import { useState, useRef } from 'react';
import AboutDialog from '../../AboutDialog/AboutDialog';
import BackgroundIcon from '../../../icons/BackgroundIcon';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import MoreIcon from '@material-ui/icons/MoreVert';
import CollaborationViewIcon from '@material-ui/icons/AccountBox';
import GridViewIcon from '@material-ui/icons/Apps';
import SettingsIcon from '../../../icons/SettingsIcon';
import {
    styled,
    Theme,
    useMediaQuery,
    Menu as MenuContainer,
    MenuItem,
    Typography
} from '@material-ui/core';
import { isSupported } from '@twilio/video-processors';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import FlipCameraIcon from '../../../icons/FlipCameraIcon';
import useFlipCameraToggle from '../../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import { IconButton } from '@mui/material';
import { useAppState } from '../../../state';

export const IconContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '1.5em',
    marginRight: '0.3em'
});

export default function Menu(props: { buttonClassName?: string }) {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const [aboutOpen, setAboutOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);

    const { setIsGalleryViewActive, isGalleryViewActive } = useAppState();
    const { setIsChatWindowOpen } = useChatContext();
    const { setIsBackgroundSelectionOpen } = useVideoContext();

    const anchorRef = useRef<HTMLButtonElement>(null);
    const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();

    return (
        <>
            <IconButton
                ref={anchorRef}
                className={props.buttonClassName}
                sx={{
                    borderRadius: '25px',
                    width: '55px',
                    marginRight: '20px',
                    color: '#ffffff',
                    background: '#525252',
                    '&:hover': { background: '#525252' }
                }}
                onClick={() => setMenuOpen(isOpen => !isOpen)}>
                <MoreIcon />
            </IconButton>

            <MenuContainer
                open={menuOpen}
                onClose={() => setMenuOpen(isOpen => !isOpen)}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: isMobile ? -55 : 'bottom',
                    horizontal: 'center'
                }}>
                <MenuItem onClick={() => setSettingsOpen(true)}>
                    <IconContainer>
                        <SettingsIcon />
                    </IconContainer>
                    <Typography variant="body1">Audio and Video Settings</Typography>
                </MenuItem>

                {isSupported && (
                    <MenuItem
                        onClick={() => {
                            setIsBackgroundSelectionOpen(true);
                            setIsChatWindowOpen(false);
                            setMenuOpen(false);
                        }}>
                        <IconContainer>
                            <BackgroundIcon />
                        </IconContainer>
                        <Typography variant="body1">Backgrounds</Typography>
                    </MenuItem>
                )}

                {flipCameraSupported && (
                    <MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
                        <IconContainer>
                            <FlipCameraIcon />
                        </IconContainer>
                        <Typography variant="body1">Flip Camera</Typography>
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        setIsGalleryViewActive(isGallery => !isGallery);
                        setMenuOpen(false);
                    }}>
                    <IconContainer>
                        {isGalleryViewActive ? (
                            <CollaborationViewIcon style={{ fill: '#707578', width: '0.9em' }} />
                        ) : (
                            <GridViewIcon style={{ fill: '#707578', width: '0.9em' }} />
                        )}
                    </IconContainer>
                    <Typography variant="body1">
                        {isGalleryViewActive ? 'Speaker View' : 'Gallery View'}
                    </Typography>
                </MenuItem>
            </MenuContainer>
            <AboutDialog
                open={aboutOpen}
                onClose={() => {
                    setAboutOpen(false);
                    setMenuOpen(false);
                }}
            />
            <DeviceSelectionDialog
                open={settingsOpen}
                onClose={() => {
                    setSettingsOpen(false);
                    setMenuOpen(false);
                }}
            />
        </>
    );
}
