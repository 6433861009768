import { Button, Grid, IconButton, Link, Paper, Radio, Typography, styled } from '@mui/material';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useState } from 'react';
import _ from 'lodash';
import { SupportPackage, getAllPackages, getAnnualPackages } from './PackagesUtils';
import { PlanSideRow } from './PlanSideRow';
const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '';
export const PurchaseNowCard = ({ goBack }: { goBack: () => void }) => {
    const [switchToAnnual, setSwitchToAnnual] = useState<boolean>(true);
    const availablePlans = switchToAnnual ? getAnnualPackages() : getAllPackages();
    return (
        <Grid
            container
            direction="row"
            justifyContent="start"
            alignItems="flex-start"
            spacing={2}
            style={{ paddingBottom: 10 }}>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontSize: { xs: '20px', md: '32px' },
                        fontWeight: 'bold',
                        fontFamily: 'Fira Sans',
                        color: '#525252',
                        borderBottom: '1px solid #E4E4E4',
                        paddingBottom: '5px'
                    }}>
                    <IconButton aria-label="back" onClick={goBack}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    Purchase a Plan
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontSize: '13px',
                        fontWeight: '300',
                        fontFamily: 'Roboto',
                        color: '#000000',
                        paddingBottom: '10px',
                        marginBottom: '5px',
                        borderBottom: '1px solid #E4E4E4'
                    }}>
                    We offer four coaching support plans that recur on a yearly or monthly bases. We
                    believe in flexibility for our clients so you may change plans easily and
                    <span style={{ fontWeight: 'bold' }}> pause/cancel at any time.</span>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper
                    elevation={0}
                    sx={{
                        display: 'flex',
                        width: '100%',
                        backgroundColor: 'transparent',
                        justifyContent: 'space-between',
                        marginLeft: '-6px'
                    }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <RadioSelectPlanType
                            checked={switchToAnnual}
                            onChange={() => setSwitchToAnnual(true)}
                        />
                        <span
                            style={{
                                paddingLeft: '5px',
                                fontWeight: 'bold',
                                fontSize: '15px',
                                fontFamily: 'Roboto',
                                color: '#43464B'
                            }}>
                            Annual Plans
                        </span>
                        <span
                            style={{
                                backgroundImage: `url(/img/underline.svg)`,
                                backgroundPositionY: 'bottom',
                                backgroundRepeat: 'no-repeat',
                                padding: '4px',
                                fontWeight: 'bold',
                                fontSize: '15px',
                                fontFamily: 'Roboto',
                                color: '#F35A5A'
                            }}>
                            SAVE {'>'} 20%
                        </span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <RadioSelectPlanType
                            checked={!switchToAnnual}
                            onChange={() => setSwitchToAnnual(false)}
                        />
                        <span
                            style={{
                                paddingLeft: '5px',
                                fontWeight: '400',
                                fontSize: '15px',
                                fontFamily: 'Roboto',
                                color: '#43464B'
                            }}>
                            Monthly Plans
                        </span>
                    </div>
                </Paper>
            </Grid>
            {_.chain(availablePlans)
                .filter(av => av.type !== undefined)
                .value()
                .map((plan: SupportPackage) => (
                    <PlanSideRow key={plan.name} planData={plan} />
                ))}
            <Grid item xs={12} sx={{ borderBottom: '1px solid #E4E4E4' }}>
                <Grid container sx={{ padding: '15px 0px' }}>
                    <Grid item xs={8}>
                        <Typography
                            sx={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                fontFamily: 'Fira Sans',
                                color: '#F35A5A'
                            }}>
                            1-HOUR TOP-UP
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { xs: '12px', lg: '12px' },
                                fontWeight: '300',
                                fontFamily: 'Roboto',
                                color: '#000000'
                            }}>
                            Non-recurring (one-time) top-ups if you don’t need the next highest
                            plan.
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            sx={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                fontFamily: 'Fira Sans',
                                color: '#F35A5A',
                                textAlign: 'left'
                            }}>
                            $139
                            <span
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    fontFamily: 'Fira Sans',
                                    position: 'relative',
                                    color: '#F35A5A',
                                    top: '-8px'
                                }}>
                                EACH
                            </span>
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { xs: '11px', lg: '12px' },
                                fontWeight: '300',
                                fontFamily: 'Roboto',
                                color: '#000000',
                                textAlign: 'left'
                            }}>
                            $CAD, per top-up
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Link
                    href={`${endpoint}/account/purchase_plan`}
                    target={'_blank'}
                    sx={{ textDecoration: 'none' }}>
                    <PurchaseButton>PURCHASE A PLAN</PurchaseButton>
                </Link>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontSize: '9px',
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        color: '#A5A5A5',
                        textAlign: 'center'
                    }}>
                    BOOK UP TO 90 DAYS IN ADVANCE. PAYMENT BEGINS ON START DATE.
                </Typography>
            </Grid>
        </Grid>
    );
};

const PurchaseButton = styled(Button)(() => ({
    backgroundColor: '#09C4F7',
    color: '#FFFFFF',
    padding: '8px 60px',
    borderRadius: '8px',
    '&:hover': {
        backgroundColor: '#00B1E2'
    }
}));

const RadioSelectPlanType = styled(Radio)(() => ({
    color: '#43464B',
    margin: '0px',
    padding: '0px',
    '&.Mui-checked': {
        color: '#43464B'
    }
}));
