import { useCallback, useEffect, useState } from 'react';
import { createNewAction, loadMyBoard, updateActionMutation } from './boardModel';
import { useAppState } from '../../state';
import _ from 'lodash';
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateBoardGoalActionByBoardId, onUpdateBoardGoalActionByBoardId } from './API';

export interface IBoardAction {
    id: string;
    link: {
        resourceLink: string;
    } | null;
    actionStatus: 'INCOMPLETE' | 'COMPLETED';
    dueDate: string;
    actionTitle: string;
}

export default function useBoardAction() {
    const [actionsByAccount, setActionsByAccount] = useState<IBoardAction[]>([]);
    const [actionGoalParent, setActionGoalParent] = useState('');
    const { sessionInfo } = useAppState();

    const addNewAction = useCallback(
        (title: string) => {
            createNewAction(
                sessionInfo?.accountId,
                actionGoalParent,
                title,
                sessionInfo?.Profile,
                sessionInfo?.userName
            );
            console.log('Order to add new action', title, 'forGoalId', actionGoalParent);
        },
        [actionGoalParent, sessionInfo?.Profile, sessionInfo?.accountId, sessionInfo?.userName]
    );

    const updateAction = useCallback(
        (action: IBoardAction) => {
            const boardId = _.replace(sessionInfo?.accountId, 'account', 'board');
            updateActionMutation(action, boardId, sessionInfo?.userName, sessionInfo?.Profile);
            console.log('Order to update new action', action);
        },
        [sessionInfo?.Profile, sessionInfo?.accountId, sessionInfo?.userName]
    );

    useEffect(() => {
        const boardId = _.replace(sessionInfo?.accountId, 'account', 'board');
        if (boardId) {
            loadMyBoard(sessionInfo?.accountId).then(actionsLoaded => {
                const actionsRaw = actionsLoaded.map((goal: any) => {
                    if (goal?.goalStatus === 'INCOMPLETE') {
                        setActionGoalParent(goal?.id);
                    }
                    return goal?.actions?.items || [];
                });
                setActionsByAccount(_.flattenDeep(actionsRaw));
            });

            const onCreateActionListener: any = API.graphql(
                graphqlOperation(onCreateBoardGoalActionByBoardId, { accountBoardId: boardId })
            );
            const onCreateActionListenerSubscription = onCreateActionListener.subscribe({
                next: ({ provider, value }: any) => {
                    const { data } = value;
                    const newAction = data?.onCreateBoardGoalActionByBoardId;
                    setActionsByAccount(prevActions => {
                        const newActions = [...prevActions, newAction];
                        return newActions;
                    });
                },
                error: (error: any) => console.warn(error)
            });

            const onUpdateActionListener: any = API.graphql(
                graphqlOperation(onUpdateBoardGoalActionByBoardId, { accountBoardId: boardId })
            );
            const onUpdateActionListenerSubscription = onUpdateActionListener.subscribe({
                next: ({ provider, value }: any) => {
                    const { data } = value;
                    const updatedAction = data?.onUpdateBoardGoalActionByBoardId;
                    console.log('updatedAction', updatedAction);

                    // const newActions = _.map(actionsByAccount, (action: IBoardAction) => {
                    //     if (action.id === updatedAction.id) {
                    //         return updatedAction;
                    //     }
                    //     return action;
                    // });
                    setActionsByAccount(prevActions => {
                        const newActions = _.map(prevActions, (action: IBoardAction) =>
                            action.id === updatedAction.id ? updatedAction : action
                        );
                        console.log('prev', prevActions, newActions);
                        return newActions;
                    });
                },
                error: (error: any) => console.warn(error)
            });

            return () => {
                setActionsByAccount([]);
                onCreateActionListenerSubscription.unsubscribe();
                onUpdateActionListenerSubscription.unsubscribe();
            };
        }
    }, [sessionInfo?.accountId]);

    return { actionsByAccount, actionGoalParent, addNewAction, updateAction };
}
