import { Divider, Grid, IconButton, Paper, Stack, Typography, styled } from '@mui/material';
import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

export const CoachQuickIntroGuide = () => {
    const [cueExtendIndex, setCueExtendIndex] = React.useState<null | number>(null);
    return (
        <Stack
            spacing={2}
            alignItems="center"
            sx={{
                height: '100%',
                paddingTop: 2,
                overflow: 'auto'
            }}>
            <Typography
                sx={{
                    fontSize: '22px',
                    fontFamily: 'Fira Sans',
                    color: '#52D1B7',
                    fontWeight: 'bold'
                }}>
                Cue Cards for Effective Intro Sessions
            </Typography>
            {_.isNumber(cueExtendIndex) ? (
                <>
                    <FullCueCard
                        cardInfo={CueCardData[cueExtendIndex]}
                        onClose={() => setCueExtendIndex(null)}
                    />
                    <CallSmallNote>CLICK X TO COLLAPSE CARD </CallSmallNote>
                </>
            ) : (
                <>
                    {CueCardData.map((card, index) => (
                        <CoachConnectEstablishedGuide
                            key={index}
                            cardInfo={card}
                            onExtend={() => setCueExtendIndex(index)}
                        />
                    ))}
                    <CallSmallNote>CLICK EACH CUE CARD TO EXPAND, </CallSmallNote>
                </>
            )}
        </Stack>
    );
};

const CueInstructions = styled(Typography)(() => ({
    color: '#fff',
    fontSize: '16px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    lineHeight: '1.5rem'
}));

const CueTitle = styled(Typography)(() => ({
    color: '#fff',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: 'Fira Sans',
    '&.collapsed': {
        lineHeight: '2rem',
        minHeight: '6rem'
    }
}));

const CueSubTitle = styled(Typography)(() => ({
    color: '#fff',
    fontSize: '12px',
    fontFamily: 'Fira Sans'
}));

const FullCueCardPaper = styled(Paper)(() => ({
    background: '#52D1B7',
    padding: 10,
    borderRadius: 10,
    margin: '10px 0 10px 0 !important'
}));

const CallSmallNote = styled(Typography)(() => ({
    color: '#A5A5A5',
    fontSize: '10px',
    fontFamily: 'Roboto'
}));

const FullCueCard = ({ cardInfo, onClose }: { onClose: () => void; cardInfo: ICueCardData }) => {
    return (
        <FullCueCardPaper elevation={3}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sx={{ margin: '5px 0 0px 10px', borderBottom: '2px solid #fff ' }}>
                    <CueTitle>{cardInfo.title}</CueTitle>
                    <CueSubTitle sx={{ padding: '10px 0' }}>{cardInfo.subtitle}</CueSubTitle>
                </Grid>
                {cardInfo.instructions.map((instruction, i) => (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            margin: '10px'
                        }}
                        key={i}>
                        <CueTitle>{instruction.title}</CueTitle>
                        <ul
                            className="cue-card-instructions-bullets"
                            style={{
                                color: '#fff'
                            }}>
                            {instruction.steps.map((step, o) => (
                                <li key={o}>
                                    <CueInstructions sx={{ padding: '2px' }}>
                                        {step}
                                    </CueInstructions>
                                </li>
                            ))}
                        </ul>
                        {i + 1 < cardInfo.instructions.length && (
                            <Divider sx={{ borderColor: '#fff', mt: 2 }} />
                        )}
                    </Grid>
                ))}

                <Grid
                    item
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                    <IconButton
                        aria-label="extend"
                        sx={{ color: '#fff', fontSize: '18px' }}
                        onClick={onClose}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Grid>
            </Grid>
        </FullCueCardPaper>
    );
};

const CueCardPaper = styled(Paper)(() => ({
    background: '#52D1B7',
    position: 'relative',
    padding: '10px 20px',
    borderRadius: 10,
    height: '134px',
    maxWidth: '440px',
    cursor: 'pointer',
    '&:hover': {
        background: '#39BEA3'
    }
}));

const CoachConnectEstablishedGuide = ({
    onExtend,
    cardInfo
}: {
    onExtend: () => void;
    cardInfo: ICueCardData;
}) => {
    return (
        <CueCardPaper elevation={3} onClick={onExtend}>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <CueTitle className="collapsed">{cardInfo.title}</CueTitle>
                    <CueSubTitle>{cardInfo.subtitle}</CueSubTitle>
                </Grid>
                <Grid item xs={1}>
                    <Divider orientation="vertical" sx={{ borderColor: '#fff !important' }} />
                </Grid>
                <Grid item xs={6}>
                    {cardInfo.instructions.map((instruction, index) => (
                        <CueInstructions key={index}>{instruction.title}</CueInstructions>
                    ))}
                    <span style={{ position: 'absolute', right: '5px', bottom: '-5px' }}>
                        <IconButton aria-label="extend" sx={{ color: '#fff', fontSize: '18px' }}>
                            <AddIcon fontSize="inherit" />
                        </IconButton>
                    </span>
                </Grid>
            </Grid>
        </CueCardPaper>
    );
};

interface ICueCardData {
    title: string;
    subtitle: string;
    instructions: { title: string; steps: string[] }[];
}
export const CueCardData = [
    {
        title: '1) Connect & Establish Trust',
        subtitle: '~5 mins  / 15%',
        instructions: [
            {
                title: 'a) Small Talk',
                steps: ['Ask: Where calling in from? 📍', 'Ask: Get up to anything this week? ⛷️']
            },
            {
                title: 'b) Set Agenda',
                steps: ['30-mins to assess mutual fit', 'Structure: Them, Youthfully, Next Steps']
            },
            {
                title: 'c) Introduce You',
                steps: ['Quick snapshot about background 🚀']
            },
            {
                title: 'd) Introduce Youthfully',
                steps: [
                    'Leader in holistic 1:1 youth coaching',
                    '12+ years preparing 1,000+ students 🎖️',
                    'Our approach is tailored to each student'
                ]
            }
        ]
    },
    {
        title: '2) Label Problem & Show Value',
        subtitle: '~10 mins  / 30%',
        instructions: [
            {
                title: 'a) Ask (why)',
                steps: ['Ask: Why they need coaching? What caused you to reach out? 🔍']
            },
            {
                title: 'b) Label problem',
                steps: [
                    'Obsolete: Parent out of touch',
                    'Concerned: Parent knows it’s competitive',
                    'Limited: Parent lacks time or influence ⌚'
                ]
            },
            {
                title: 'c) Show value',
                steps: [
                    'Obsolete: Up-to-date knowledge/success with best training & coach network',
                    'Concerned: Know what is competitive and how to get students to next level 📈',
                    'Limited: Impartial & reliable guide for students; parents get time/energy back'
                ]
            }
        ]
    },
    {
        title: '3) Clarify Approach & Questions',
        subtitle: '~10 mins  / 30%',
        instructions: [
            {
                title: 'a) Youthfully approach',
                steps: [
                    'State differentiator—student has what it takes to be successful with guidance',
                    '3 Keys: Self-awareness, unique experiences/accomplishments, skills 🔑',
                    'Success at uni & beyond (examples)'
                ]
            },
            {
                title: 'b) How it works',
                steps: [
                    '1-on-1, monthly recurring support plans with different coaching hours per month ',
                    'Discover, Design, Fulfill'
                ]
            },
            {
                title: 'c) Answer questions',
                steps: [
                    'Success rate? ~9x higher chance of offers',
                    'Too early/late to start? Best time is now',
                    'Edit essays? Yes, but we don’t write'
                ]
            }
        ]
    },
    {
        title: '4) Agree on Next Steps',
        subtitle: '~5 mins  / 15%',
        instructions: [
            {
                title: 'a) Convey opportunity',
                steps: ['“I’d love to start by helping you…” 💡', 'Limited capacity ⌛']
            },
            {
                title: 'b) Confirm next steps',
                steps: [
                    'Yes, start now → Book first call together',
                    'Yes, not now → Pre-book start date 📅',
                    'Maybe/No → “What’s on your mind?” 🤔'
                ]
            },
            {
                title: 'c) Input call status',
                steps: ['Complete Call Status Form (required)']
            }
        ]
    }
];
