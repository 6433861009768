import Tooltip from '@material-ui/core/Tooltip';

import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PausePresentationIcon from '@material-ui/icons/PausePresentation';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { IconButton } from '@mui/material';

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
    const screenShareParticipant = useScreenShareParticipant();
    const { toggleScreenShare, isSharingScreen } = useVideoContext();
    const disableScreenShareButton = Boolean(screenShareParticipant);
    const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
    const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;
    let tooltipMessage = '';

    if (disableScreenShareButton) {
        tooltipMessage = SHARE_IN_PROGRESS_TEXT;
    }

    if (!isScreenShareSupported) {
        tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
    }

    return isSharingScreen ? (
        <IconButton
            disabled={isDisabled && !isSharingScreen}
            sx={{
                borderRadius: '25px',
                width: '55px',
                color: '#ffffff',
                background: '#F35A5A',
                '&:hover': { background: '#F35A5A' }
            }}
            onClick={toggleScreenShare}>
            {isSharingScreen && !isDisabled ? (
                <PausePresentationIcon />
            ) : isDisabled ? (
                <CancelPresentationIcon />
            ) : (
                <PresentToAllIcon />
            )}
        </IconButton>
    ) : (
        <Tooltip
            title={tooltipMessage}
            placement="top"
            PopperProps={{ disablePortal: true }}
            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
            <span>
                {/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
                <IconButton
                    sx={{
                        borderRadius: '25px',
                        width: '55px',
                        color: '#ffffff',
                        background: '#525252',
                        '&:hover': { background: '#525252' },
                        '&:disabled': { background: '#525252' }
                    }}
                    onClick={toggleScreenShare}
                    disabled={isDisabled}
                    data-cy-share-screen>
                    <PresentToAllIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
}
