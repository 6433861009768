import React, { useState, useEffect } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export enum Steps {
    roomNameStep,
    deviceSelectionStep
}

export default function PreJoinScreens() {
    const { userName } = useAppState();
    const { getAudioAndVideoTracks } = useVideoContext();

    const [mediaError, setMediaError] = useState<Error>();

    useEffect(() => {
        if (!mediaError) {
            getAudioAndVideoTracks().catch(error => {
                console.log('Error acquiring local media:');
                console.dir(error);
                setMediaError(error);
            });
        }
    }, [getAudioAndVideoTracks, mediaError]);

    return (
        <IntroContainer>
            <MediaErrorSnackbar error={mediaError} />
            <DeviceSelectionScreen userName={userName} />
        </IntroContainer>
    );
}
