import React, { SVGProps } from 'react';

export default function YouthfullyLogo(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2000 500"
            height={60}
            {...props}>
            <defs>
                <style>{'.cls-1{fill:#52d1b7}.cls-2{fill:#fff}'}</style>
            </defs>
            <path
                className="cls-1"
                d="M412.81 148a12.19 12.19 0 0 0-14.55 7c-18.49 44-41.25 65.16-55.6 66.65-5.2.5-7.45-1.85-8.72-3.7-5.57-8-5.21-28.07 8.12-54.65a12.19 12.19 0 0 0-21.79-10.93c-16.38 32.63-18.7 61.63-6.38 79.43 7.09 10.27 18.5 15.35 31.28 14.08 13.36-1.39 27-9.52 39.87-23.21-2.29 9.62-4.64 18.27-7 26.29 8 1 18.37 2.64 24 4.94 7.62-25.89 13.87-55.78 19.53-92.33a12.19 12.19 0 0 0-8.76-13.57Z"
            />
            <path
                className="cls-1"
                d="M360.41 86.94c-89.91 0-163.06 73.15-163.06 163.06s73.15 163.06 163.06 163.06c61.88 0 98.49-36.7 106.33-70.79 6.92-30.16-7-58.55-35.37-72.33a135.28 135.28 0 0 0-31-10.55 162.11 162.11 0 0 0-25.82-3c-26.76-.77-51.61 5.77-68.24 16.19-17.08 10.7-26.49 25.62-26.49 42a39.82 39.82 0 0 0 39.78 39.78c30.26 0 54.16-23.21 72.81-71.71a111.56 111.56 0 0 1 28.29 9.21c18.35 8.9 26.67 25.71 22.26 44.95-5.74 25-34 51.87-82.58 51.87-76.47 0-138.69-62.21-138.69-138.69S283.91 111.3 360.38 111.3 499.11 173.53 499.11 250a137.72 137.72 0 0 1-12.29 57.17A12.18 12.18 0 1 0 509 317.22 162 162 0 0 0 523.48 250c0-89.91-73.15-163.06-163.07-163.06ZM319.63 330a15.42 15.42 0 0 1-15.41-15.41c0-9.87 8.19-17.07 15-21.37C330.77 286 348 281.16 367 280.67 350.71 319.39 333.54 330 319.63 330Z"
            />
            <path
                className="cls-2"
                d="m599.55 380.74 34.42-72-53.35-115.1h34.43l17.61 40c6.57 15 11.82 27.06 17.34 40.73h.53c6-14.45 12.61-29.17 18.92-43.36l16.82-37.32h32.32l-85.93 187.05ZM786.66 190.74c40.2 0 70.16 29.43 70.16 67.54s-30 67.53-70.16 67.53-70.17-29.43-70.17-67.53 29.96-67.54 70.17-67.54Zm0 107.22c22.07 0 38.37-17.34 38.37-39.68s-16.3-39.68-38.37-39.68-38.37 17.34-38.37 39.68S764.58 298 786.66 298ZM963 308.47c-10.78 10-24.7 17.34-42 17.34-14.19 0-26.54-5-34.42-13.66S875 291.65 875 273v-79.37h31.8v74.11c0 11.56 1.84 18.4 5.78 22.86 4.73 5.52 11 7.1 17.08 7.1 12.62 0 25-7.1 33.64-16.82v-87.25h31.8v129.3h-31.58v-14.2ZM1105.7 319.77c-5.78 3.42-16.29 6-26.28 6-11.3 0-23.39-3.41-31.54-12.61-6.57-7.09-10.51-17.87-10.51-37.05V220.7h-22.6v-27.07h22.6v-34.42l31.8-5.52v39.94h36.53v27.07h-36.53v51c0 14.46 2.63 26.54 17.08 26.54 6.83 0 14.19-2.63 19.45-5.52ZM1127.25 122.15h31.8v85.41l.53.26c10.51-9.72 25.22-17.08 42.3-17.08 13.93 0 26 4.73 34.17 13.67 7.62 8.14 11 18.92 11 38.1v80.42h-31.79V248c0-12.09-1.58-17.87-5.26-22.07-4.2-5-10-7.1-16.82-7.1-12.87 0-25.75 7.36-34.16 16.29v87.78h-31.8ZM1283.62 226.74h-18.4v-33.11h18.4v-19.18c0-18.13 3.67-33.64 13.66-43.1 9.2-8.93 23.65-12.09 36.53-12.09a63.74 63.74 0 0 1 22.07 3.68v32.33a82.67 82.67 0 0 0-17.34-2.37c-12.35 0-15 7.89-15 18.92v21.81h30v33.11h-30v96.19h-39.94Zm176.07 83.83c-10 8.68-22.6 15.24-39.42 15.24-15 0-26.28-5.25-33.64-12.87-8.41-8.67-12.35-20.5-12.35-39.68v-79.63h40v73.58c0 9.46 1.57 15 5 18.66 3.42 3.94 8.67 5.78 14.19 5.78 10 0 20-5.51 26.28-11.56v-86.46h39.94v129.3h-39.41v-12.09ZM1528 122.15h40v200.78h-40Zm73.06 0h40v200.78h-40Zm76.74 258.59 33.9-72-52.56-115.1h42l13.4 32.06q8.67 20.9 16.56 41h.52c5.78-14.19 11.57-27.86 17.61-41.79l13.77-31.28h39.69l-84.1 187.11Z"
            />
        </svg>
    );
}
