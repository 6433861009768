import React, { useCallback, useRef } from 'react';

import Button from '@material-ui/core/Button';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';
import VideocamIcon from '@material-ui/icons/Videocam';
import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { IconButton } from '@mui/material';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const lastClickTimeRef = useRef(0);
    const { hasVideoInputDevices } = useDevices();

    const toggleVideo = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            toggleVideoEnabled();
        }
    }, [toggleVideoEnabled]);

    return props.className ? (
        <Button
            className={props.className}
            onClick={toggleVideo}
            disabled={!hasVideoInputDevices || props.disabled}
            startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}>
            {!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Camera Off' : 'Camera On'}
        </Button>
    ) : (
        <IconButton
            disabled={!hasVideoInputDevices || props.disabled}
            sx={{
                borderRadius: '25px',
                width: '55px',
                margin: '0 20px',
                color: '#ffffff',
                background: isVideoEnabled ? '#525252' : '#F35A5A',
                '&:hover': { background: isVideoEnabled ? '#525252' : '#F35A5A' }
            }}
            onClick={toggleVideo}>
            {<VideocamIcon />}
        </IconButton>
    );
}
