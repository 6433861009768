import React from 'react';
import clsx from 'clsx';
import Participant from '../Participant/Participant';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useParticipantsContext from '../../hooks/useParticipantsContext/useParticipantsContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            overflowY: 'auto',
            background: 'rgb(79, 83, 85)',
            gridArea: '1 / 2 / 1 / 3',
            zIndex: 5,
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                gridArea: '1 / 1 / 1 / 1',
                overflowY: 'initial',
                overflowX: 'auto',
                display: 'flex',
                justifyContent: 'flex-end'
            }
        },
        transparentBackground: {
            background: 'transparent'
        },
        scrollContainer: {
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
            height: '95%'
        },
        innerScrollContainer: {
            width: `100%`,
            maxWidth: '370px',
            padding: '1.5em 0',
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                padding: `${theme.sidebarMobilePadding}px`,
                display: 'flex'
            }
        }
    })
);

export default function ParticipantList() {
    const classes = useStyles();
    const { room } = useVideoContext();
    const localParticipant = room!.localParticipant;
    const { speakerViewParticipants } = useParticipantsContext();
    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
    const screenShareParticipant = useScreenShareParticipant();
    const mainParticipant = useMainParticipant();

    if (speakerViewParticipants.length === 0) return null; // Don't render this component if there are no remote participants.

    return (
        <aside className={clsx(classes.container, classes.transparentBackground)}>
            <div className={classes.scrollContainer}>
                <div className={classes.innerScrollContainer}>
                    <div style={{ height: `calc(100% / ${speakerViewParticipants.length + 1})` }}>
                        <Participant participant={localParticipant} isLocalParticipant={true} />
                    </div>
                    {speakerViewParticipants.map(participant => {
                        const isSelected = participant === selectedParticipant;
                        const hideParticipant =
                            participant === mainParticipant &&
                            participant !== screenShareParticipant &&
                            !isSelected;
                        return (
                            <div
                                key={participant.sid}
                                style={{
                                    height: `calc(100% / ${speakerViewParticipants.length + 1})`
                                }}>
                                <Participant
                                    key={participant.sid}
                                    participant={participant}
                                    isSelected={participant === selectedParticipant}
                                    onClick={() => setSelectedParticipant(participant)}
                                    hideParticipant={hideParticipant}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </aside>
    );
}
