import React from 'react';

import Button from '@material-ui/core/Button';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';
import MicONIcon from '@material-ui/icons/Mic';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { IconButton } from '@mui/material';

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const { localTracks } = useVideoContext();
    const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

    return props.className ? (
        <Button
            className={props.className}
            onClick={toggleAudioEnabled}
            disabled={!hasAudioTrack || props.disabled}
            startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
            data-cy-audio-toggle>
            {!hasAudioTrack ? 'No Audio' : isAudioEnabled ? 'Mute' : 'Unmute'}
        </Button>
    ) : (
        <IconButton
            disabled={!hasAudioTrack || props.disabled}
            sx={{
                borderRadius: '25px',
                width: '55px',
                color: '#ffffff',
                background: isAudioEnabled ? '#525252' : '#F35A5A',
                '&:hover': { background: isAudioEnabled ? '#525252' : '#F35A5A' }
            }}
            onClick={toggleAudioEnabled}>
            {<MicONIcon />}
        </IconButton>
    );
}
