import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { initDataDog } from '../../utils/datadog';

const TOKEN_ENDPOINT = process.env.REACT_APP_TOKEN_ENDPOINT || 'localhost';
export default function useYouthfullyAuth() {
    const [userName, setUser] = useState<string | null>(null);
    const [roomName, setRoomName] = useState<string | null>(null);
    const [joinToken, setJoinToken] = useState<string>();
    const [isTokenValidInprogress, setIsTokenValidInprogress] = useState(true);
    const [sessionInfo, setSessionInfo] = useState();

    const validateAccessToken = async (token: string, sessionId: string) => {
        return fetch(`${TOKEN_ENDPOINT}/api/session/token`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization: token
            },
            body: JSON.stringify({
                sessionId
            })
        }).then(res => res.json());
    };
    useEffect(() => {
        initDataDog();
        const authTokenValue = Cookies.get('authtoken');
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const sessionId = params.get('sessionId');
        if (!authTokenValue || !sessionId) {
            setIsTokenValidInprogress(false);
            window.location.href = TOKEN_ENDPOINT;
        } else {
            validateAccessToken(authTokenValue, sessionId)
                .then(res => {
                    if (res.statusCode === 200) {
                        const { userName, twilioToken } = res.body;
                        setIsTokenValidInprogress(false);
                        setJoinToken(twilioToken);
                        setUser(userName);
                        setRoomName(sessionId);
                        setSessionInfo(res.body);
                        //updateRecordingRules();
                    } else {
                        window.location.href = TOKEN_ENDPOINT;
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }

        //
    }, []);
    return { isTokenValidInprogress, joinToken, roomName, userName, sessionInfo };
}
